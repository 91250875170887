import React, { useState, useEffect } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import CategorySection from "./components/CategorySection";
import JewelleryGrid from "./components/JewelleryGrid";
import JewelleryModal from "./components/JewelleryModal";
import { useGetSupplierBySlug } from "../../hooks/profile";

// Helper function moved outside component
const adjustColor = (color, percent) => {
  try {
    const num = parseInt(color.replace("#", ""), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const G = ((num >> 8) & 0x00ff) + amt;
    const B = (num & 0x0000ff) + amt;
    return `#${(
      (1 << 24) |
      ((R < 255 ? (R < 1 ? 0 : R) : 255) << 16) |
      ((G < 255 ? (G < 1 ? 0 : G) : 255) << 8) |
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)}`;
  } catch (error) {
    console.warn("Error adjusting color:", error);
    return color; // Return original color if adjustment fails
  }
};

const SupplierProfile = () => {
  const { supplierName } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const initialCategory = searchParams.get("category");
  const { data: supplierData, isLoading } = useGetSupplierBySlug(supplierName);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedJewellery, setSelectedJewellery] = useState(null);

  // Extract theme colors from supplier data
  const theme = React.useMemo(() => {
    const primary =
      supplierData?.responseData?.supplier?.theme?.primary || "#4B5563";
    const secondary =
      supplierData?.responseData?.supplier?.theme?.secondary || "#1F2937";

    return {
      primary,
      secondary,
      primaryHover: adjustColor(primary, -10),
      secondaryHover: adjustColor(secondary, -10),
    };
  }, [supplierData]);

  // Get initial filters from URL
  const [filters, setFilters] = useState({
    subCategory: searchParams.getAll("subCategory"),
    weight: searchParams.getAll("weight"),
    purity: searchParams.getAll("purity"),
  });

  // Set initial category from URL when data loads
  useEffect(() => {
    if (supplierData?.responseData?.products && initialCategory) {
      const category = supplierData.responseData.products.find(
        (p) => p.product_id === initialCategory || p._id === initialCategory
      );

      if (category) {
        const categoryData = {
          id: category._id || category.product_id,
          category_id: category.product_id || category._id,
          name: category.name,
          image: category.sampleImage,
          product_category: category.product_category,
        };

        // Only set the category if it's not already set
        setSelectedCategory((prev) =>
          prev?.id === categoryData.id ? prev : categoryData
        );
      }
    }
  }, [supplierData, initialCategory]);

  // Persist category selection across refreshes
  useEffect(() => {
    const categoryParam = searchParams.get("category");
    if (
      categoryParam &&
      !selectedCategory &&
      supplierData?.responseData?.products
    ) {
      const category = supplierData.responseData.products.find(
        (p) => p.product_id === categoryParam || p._id === categoryParam
      );

      if (category) {
        setSelectedCategory({
          id: category._id || category.product_id,
          category_id: category.product_id || category._id,
          name: category.name,
          image: category.sampleImage,
          product_category: category.product_category,
        });
      }
    }
  }, [searchParams, selectedCategory, supplierData]);

  // Debug logging
  useEffect(() => {
    console.log("URL Category:", initialCategory);
    console.log("Selected Category:", selectedCategory);
    console.log("Supplier Data:", supplierData);
    console.log("Current Filters:", filters);
  }, [initialCategory, selectedCategory, supplierData, filters]);

  const handleCategorySelect = (category) => {
    const categoryId = category.id || category.category_id;
    setSelectedCategory(category);

    // Update URL with category ID while preserving other params
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("category", categoryId);
    setSearchParams(newSearchParams);
  };

  const handleCategoryBack = () => {
    setSelectedCategory(null);
    // Clear all search params when going back
    setSearchParams(new URLSearchParams());
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!supplierData?.responseData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center px-4">
          <div className="mb-8">
            <svg
              className="mx-auto h-24 w-24 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Jeweller Not Found
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            We couldn't find the jeweller you're looking for. The sparkle seems
            to have gone elsewhere!
          </p>
          <a
            href="/"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700"
          >
            Return to Homepage
          </a>
        </div>
      </div>
    );
  }

  const { supplier, products } = supplierData.responseData;

  return (
    <div
      className="min-h-screen relative"
      style={{
        backgroundColor: theme?.secondary || "#1F2937",
      }}
    >
      {/* Background gradient overlay with reduced opacity */}
      <div
        className="absolute inset-0 opacity-30"
        style={{
          background: `linear-gradient(45deg, ${theme?.primary}40 0%, ${theme?.secondary}40 100%)`,
        }}
      />

      {/* Main content */}
      <div className="relative z-10 max-w-7xl mx-auto px-3 sm:px-6 lg:px-8 py-4 sm:py-8">
        {/* Glassmorphism header */}
        <header className="mb-4 sm:mb-10 rounded-xl sm:rounded-2xl overflow-hidden backdrop-blur-xl bg-white/10">
          <div className="p-3 sm:p-8">
            <div className="flex flex-col sm:flex-row items-center justify-between gap-3 sm:gap-6">
              <div className="flex items-center gap-3 sm:gap-6 w-full sm:w-auto">
                <div className="relative">
                  <div className="absolute inset-0 bg-white/20 rounded-full blur-md transform -translate-y-1"></div>
                  <img
                    src={supplier?.business_logo}
                    alt={supplier?.business_name}
                    className="relative w-14 h-14 sm:w-24 sm:h-24 rounded-full object-cover border-2 border-white/50"
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <h1 className="text-xl sm:text-4xl font-bold text-white mb-1 sm:mb-2 truncate">
                    {supplier?.business_name}
                  </h1>
                  <div className="flex flex-wrap items-center gap-1 sm:gap-4 text-white/80 text-xs sm:text-base">
                    <span className="flex items-center">
                      <i className="fas fa-map-marker-alt mr-1"></i>
                      {supplier?.city}
                    </span>
                    <span className="hidden sm:inline">•</span>
                    <span className="flex items-center">
                      <i className="fas fa-phone mr-1"></i>
                      {supplier?.phone}
                    </span>
                  </div>
                </div>
              </div>

              {/* Stats - Integrated into the same row */}
              <div className="flex gap-3 sm:gap-6 text-white/80">
                <div className="text-center">
                  <div className="text-lg sm:text-2xl font-bold text-white">
                    {supplierData?.responseData?.products?.length || 0}
                  </div>
                  <div className="text-xs sm:text-sm">Collections</div>
                </div>
                <div className="text-center">
                  <div className="text-lg sm:text-2xl font-bold text-white">
                    {supplierData?.responseData?.totalJewellery || 0}
                  </div>
                  <div className="text-xs sm:text-sm">Products</div>
                </div>
              </div>
            </div>
          </div>
        </header>

        {!selectedCategory && !initialCategory ? (
          <div className="space-y-6 sm:space-y-8">
            <CategorySection
              onCategorySelect={handleCategorySelect}
              products={supplierData?.responseData?.products}
              theme={theme}
            />
          </div>
        ) : (
          <div className="flex flex-col md:flex-row gap-4 sm:gap-8">
            <main className="flex-1 bg-white/95 backdrop-blur-xl rounded-xl sm:rounded-2xl shadow-xl">
              <JewelleryGrid
                category={selectedCategory}
                supplierId={supplierData?.responseData?.supplier?._id}
                filters={filters}
                setFilters={setFilters}
                onJewelleryClick={(jewellery) => {
                  setSelectedJewellery(jewellery);
                  setShowModal(true);
                }}
                onCategoryBack={handleCategoryBack}
                theme={theme}
              />
            </main>
          </div>
        )}

        <JewelleryModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          jewellery={selectedJewellery}
          theme={theme}
        />
      </div>
    </div>
  );
};

export default SupplierProfile;
