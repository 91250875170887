import { SELL_JEWELRY_STEPS } from "../home.constant";
import StepCard from "./stepsCard";

const SellJewellerySteps = () => {
  return (
      <section className="common-section threeSimple-step ">
        <div className="container">
          <div className="content-section">
            <h1 className="text-3xl lg:text-4xl">
              sell your <span className="pink-color-text">Gold jewelry</span>{" "}
              stock with <span className="pink-color-text">3 simple steps</span>
            </h1>
            <h5 className="text-2xl">
              Showcase and sell your gold jewelry to retailers and make your{" "}
            </h5>
            <h5 className="dark-word text-2xl">dukan a brand.</h5>
            <div className="three-step">
              <div className="row">
                {SELL_JEWELRY_STEPS.map((step, index) => (
                  <StepCard key={index} {...step} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default SellJewellerySteps;
