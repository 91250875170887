import StepIcon1 from '../../assets/images/icons/upload_ic.svg'
import StepIcon2 from '../../assets/images/icons/order_ic.svg'
import StepIcon3 from '../../assets/images/icons/payment_ic.svg'
export const SELL_JEWELRY_STEPS = [
    {
        icon: StepIcon1,
        title: 'upload jewelry', // Changed 'jewellery' to 'jewelry'
        description: 'upload unlimited gold jewelry. showcase to each retailer. get your first order.'
    },
    {
        icon: StepIcon2,
        title: 'real time jewelry try on', // Changed 'jewellery' to 'jewelry'
        description: 'Now Retailers can not only see but also try out the jewelry stock on real time with our AR technology'
    },
    {
        icon: StepIcon3,
        title: 'connect with retailers',
        description: 'With advance features and data analytics get your connection with genuine retailers'
    }
];