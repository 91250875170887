import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import EnquiryModal from "./Enquiry/enquiryModal";
import DailyFeedModal from "./dailyFeedModal";
import EditDailyFeedModal from "./editDailyFeedModal";
import DeleteModal from "../../Components/DeleteConfirmationModal";
import { COLUMNS } from "./dashboard.constant";
import { useSocket } from "../../context/socket";
import { useJewelleryInquiry, useDeleteEnquiry } from "../../hooks/enquiry";
import { useGetDailyFeed } from "../../hooks/dailyFeed";
import { useGetUserProfile } from "../../hooks/profile";
import { AiOutlineSend, AiOutlineArrowRight } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import SummaryCard from "../../Components/summaryCard";
import { summaryDetails } from "../Dashboard/dashboard.constant";
import { useGetPerformance } from "../../hooks/performance";

const Main = () => {
  const socket = useSocket();
  const [enquiryModal, setInquiryModal] = useState(false);
  const [dailyFeedModal, setDailyFeedModal] = useState(false);
  const [editDailyFeedModal, setEditDailyFeedModal] = useState(false);
  const [editFeedData, setEditFeedData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [inquiry, setInquiry] = useState();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [showGallery, setShowGallery] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const { data, error, isLoading, refetch } = useJewelleryInquiry(3, 1);
  const profile = useGetUserProfile();
  const [profileData, setProfileData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const GetPerformanceDetails = useGetPerformance();
  const [totalSummary, setTotalSummary] = useState();
  const impersonate = localStorage.getItem("impersonate");
  useEffect(() => {
    fetchPerformance();
  }, []);

  const fetchPerformance = async () => {
    const list = await GetPerformanceDetails.mutateAsync();
    setTotalSummary(list.responseData);
  };
  const {
    data: dailyFeed,
    error: dailyFeedError,
    isLoading: dailyFeedLoading,
    refetch: deilyFeedRefetch,
  } = useGetDailyFeed();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await profile.mutateAsync();
        setProfileData(data.responseData);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (data) {
      setFilteredData(data?.responseData?.items.slice(0, 3));
    }
  }, [data]);

  const handleCloseModal = () => {
    setDeleteModal(!deleteModal);
    refetch();
  };

  const deleteEnquiry = useDeleteEnquiry(handleCloseModal);

  useEffect(() => {
    if (socket) {
      socket.on("jewellery_inquiry", (data) => {
        refetch();
      });
    }
  }, [socket]);

  const openModal = (data) => {
    setInquiry(data);
    setInquiryModal(true);
  };

  function closeModal() {
    setInquiryModal(false);
    setDailyFeedModal(false);
    setEditDailyFeedModal(false);
    deilyFeedRefetch();
  }

  const handleDeleteModal = (event, data) => {
    event.stopPropagation();
    setDeleteModal(!deleteModal);
    setInquiry(data);
  };

  const onClickAddFeed = () => {
    setDailyFeedModal(true);
  };

  const onEditDailyFeed = (feedData) => {
    setEditFeedData(feedData);
    setEditDailyFeedModal(true);
    deilyFeedRefetch();
  };

  const renderDailyFeed = () => {
    return (
      <div className="w-full flex mb-3 overflow-x-auto gap-3 p-2">
        <div
          className="p-2.5 rounded-full bg-primary-pink flex items-center justify-center cursor-pointer"
          onClick={onClickAddFeed}
        >
          <HiOutlinePlusSmall size={35} className="text-white" />
        </div>
        {dailyFeed &&
          dailyFeed?.responseData?.map((item, index) => (
            <div
              key={index}
              className="rounded-full h-14 w-14 cursor-pointer border-2 border-gray-400 flex-shrink-0"
              onClick={() => onEditDailyFeed(item)}
            >
              <img
                className="h-full w-full rounded-full cursor-pointer border-1 border-black"
                src={item?.jewellery_images[0]}
                alt=""
              />
            </div>
          ))}
      </div>
    );
  };

  const renderTableBody = () => {
    if (!filteredData || filteredData.length === 0) {
      return (
        <p className="font-bold flex justify-center items-center">
          No Data Found
        </p>
      );
    }
    return filteredData.map((item, index) => (
      <tr
        key={item.id}
        onClick={() => openModal(item)}
        className="hover:border hover:border-transparent hover:shadow-md hover:cursor-pointer hover:border-pink-600"
      >
        <td
          class={`px-6 py-2 whitespace-no-wrap ${
            index === filteredData?.length - 1 ? "" : "border-b border-gray-200"
          } `}
        >
          <div class="flex items-center">
            <div class="flex-shrink-0 h-10 w-10">
              <img
                class="h-10 w-10 rounded-full"
                src={item?.jewellery_images[0]}
                alt=""
              />
            </div>
            <div class="ml-4">
              <div class="text-sm leading-5 font-medium text-gray-900">
                {item?.jewellery_name}
              </div>
            </div>
          </div>
        </td>
        <td
          class={`px-6 py-2 whitespace-no-wrap ${
            index === filteredData?.length - 1 ? "" : "border-b border-gray-200"
          }`}
        >
          {item?.is_ask_weight ? (
            <div class="text-sm leading-5 font-medium text-primary-pink">
              Ask For!
            </div>
          ) : (
            item.weight || "-"
          )}
        </td>
        <td
          class={`px-6 py-2 whitespace-no-wrap ${
            index === filteredData?.length - 1 ? "" : "border-b border-gray-200"
          } text-sm leading-5 text-gray-500`}
        >
          {item.user_id.firstName}
        </td>

        <td
          class={`px-6 py-2 whitespace-no-wrap ${
            index === filteredData?.length - 1 ? "" : "border-b border-gray-200"
          } text-sm leading-5 text-gray-500`}
        >
          <span
            className={`inline-block px-2 py-1 rounded-xl text-xs font-medium bg-green-300 text-green-800`}
          >
            {item.status}
          </span>
        </td>
        <td
          class={`px-6  whitespace-no-wrap text-right ${
            index === filteredData?.length - 1 ? "" : "border-b border-gray-200"
          } text-sm leading-5 font-medium`}
        >
          <div className="flex items-center space-x-4 justify-center">
            <button
              className="text-lg text-gray-400 hover:opacity-50"
              onClick={() => openModal(item)}
            >
              <AiOutlineSend />
            </button>
            <button
              className="text-lg text-red-500 hover:opacity-50"
              onClick={(e) => handleDeleteModal(e, item)}
            >
              <RxCross2 />
            </button>
          </div>
        </td>
      </tr>
    ));
  };

  const handleDeleteConfirmation = (id) => {
    if (!id) {
      return;
    }
    deleteEnquiry.mutateAsync({ id }).then(() => {
      setFilteredData((prevData) => prevData.filter((item) => item.id !== id));
    });
    refetch();
  };

  const togglePreview = (image) => {
    setImagePreviewUrl(image);
    setPreviewOpen(!isPreviewOpen);
  };

  const closeGallery = () => {
    setShowGallery(false);
  };

  const openGallery = (images) => {
    setShowGallery(true);
    const arr = [];
    images.map((image) => {
      arr.push({
        original: image,
        thumbnail: image,
      });
    });
    setImages(arr);
  };

  const renderSummaryCards = () => {
    return summaryDetails(totalSummary).map((aSummary, index) => {
      return (
        <SummaryCard
          key={index}
          title={aSummary.title}
          subtitle={aSummary.subtitle}
          className="w-full md:w-44"
          Icon={aSummary.icon}
        />
      );
    });
  };

  return showGallery ? (
    <>
      <div className="relative h-screen mt-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8 absolute top-8 left-8 cursor-pointer text-white z-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#F64668"
          onClick={closeGallery}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <ImageGallery
          items={images}
          showFullscreenButton={true}
          showPlayButton={false}
          startIndex={currentIndex}
          showIndex={true}
          showNav={true}
          additionalClass="image-gallery" // Optional: Add a custom CSS class to ImageGallery
          onRequestClose={closeGallery}
        />
      </div>
    </>
  ) : (
    <>
      {profile.isLoading || GetPerformanceDetails.isLoading ? (
        <div className="flex justify-center items-center p-4 mt-28">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      ) : (
        <div className="common-layout min-h-screen">
          <div className="w-full md:mt-12 lg:mt-1 mb-4 md:flex md:justify-between">
            <div className="mt-10">
              <h3 className="text-3xl font-bold">
                Welcome {profileData?.business_name},
              </h3>
              <p className="text-xl text-jw-light-gray mt-2">
                Sell with jewecle and grow business digitally
              </p>
            </div>
            {/* <div className='rounded-lg bg-gray-400 p-4 mt-3'>
                  <div>
                      <div>
                          <h5 className='text-xl text-white font-bold'>Watch How</h5>
                          <p className='text-sm text-white'>Get deep insights of how things get done easily</p>
                      </div>
                  </div>
              </div> */}
          </div>
          {/* <div className='w-full mb-6 bg-white rounded-md px-3 py-3'>
              <h3 className='text-xl'>
                  Performance
              </h3>
              <div className='mt-3 md:flex'>
                  {renderPerformanceDetails()}
              </div>
          </div> */}
            <div className="w-full mb-6 bg-white rounded-md px-3 py-3">
            <h3 className="text-xl">Ready Stock</h3>
            <div className="mt-3 md:flex">{renderDailyFeed()}</div>
          </div>
          <div className="w-full lg:flex ">
            {impersonate === "1" && (
              <Link
                to="/dashboard/upload-jewellery"
                className="border-2 border-primary-pink rounded-xl overflow-hidden w-full md:w-1/4 max-w-md mx-auto mb-4 bg-white  transition duration-300 ease-in-out transform hover:scale-105 shadow-md"
              >
                <div className="p-6 flex items-center justify-center">
                  <div className="flex flex-col justify-center">
                    <p className="text-base font-bold text-gray-500 mb-4">
                      Start your journey here by uploading your overall
                      jewellery stock with a single click
                    </p>
                    <button className="text-lg text-white flex font-semibold text-center  bg-primary-pink px-4 py-2 rounded-full   transition duration-300 ease-in-out transform hover:scale-105">
                      <svg
                        className="w-6 h-6 text-white mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                      Upload Jewellery
                    </button>
                  </div>
                </div>
              </Link>
            )}

            <div
              className={`border md:ml-4 border-black rounded-lg w-full ${
                impersonate === "1" ? "lg:w-10/12" : "w-full"
              } bg-white overflow-x-auto`}
            >
              {isLoading ? (
                <>
                  <div className="flex justify-center items-center p-4">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col">
                    <div className="overflow-x-auto">
                      <table className="min-w-full">
                        <thead>
                          <tr>
                            {COLUMNS.map((column) => (
                              <th
                                key={column.uid}
                                class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {column.name}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody class="bg-white">{renderTableBody()}</tbody>
                      </table>
                    </div>
                    {data && data.responseData?.items?.length >= 3 && (
                      <div className="flex justify-end m-2 pr-1">
                        <Link to="/dashboard/enquiry">
                          <button
                            type="button"
                            className="bg-primary-pink text-white px-2 py-1 rounded-md whitespace-nowrap inline-flex items-center"
                          >
                            View All <AiOutlineArrowRight className="ml-2" />
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="w-full mb-6 bg-white rounded-md px-3 py-3">
            <h3 className="text-xl">Total Counts</h3>
            <div className="md:flex flex-wrap">{renderSummaryCards()}</div>
          </div>
        

          
          {/* <div className="w-full lg:flex gap-6 rounded-lg mt-3">
            <div className="border border-gray w-full mb-3 rounded-lg p-2 bg-white lg:w-1/2 md:px-2 md:justify-start py-2">
              <h3 className="text-xl">Top Cities</h3>
              <div className=" w-full">
                <ProgressBar percentage={75} label="Ahmedabad" />
                <ProgressBar percentage={30} label="Rajkot" />
                <ProgressBar percentage={45} label="Umreth" />
                <ProgressBar percentage={30} label="Dhanduka" />
              </div>
            </div>
          </div> */}
        </div>
      )}
      {isPreviewOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={togglePreview}
        >
          <img
            src={imagePreviewUrl}
            className="object-contain max-w-4/5 max-h-4/5"
            alt="Full Screen Preview"
          />
        </div>
      )}
      {enquiryModal && (
        <EnquiryModal
          openGallery={openGallery}
          isOpen={enquiryModal}
          closeModal={closeModal}
          togglePreview={togglePreview}
          inquiry={inquiry}
        />
      )}
      {dailyFeedModal && (
        <DailyFeedModal
          isOpen={dailyFeedModal}
          closeModal={closeModal}
          dailyFeedRefetch={deilyFeedRefetch}
        />
      )}
      {editDailyFeedModal && (
        <EditDailyFeedModal
          feedData={editFeedData}
          isOpen={editDailyFeedModal}
          closeModal={closeModal}
          dailyFeedLoading={dailyFeedLoading}
        />
      )}
      <DeleteModal
        isOpen={deleteModal}
        closeModal={handleDeleteModal}
        inquiry={inquiry}
        handleDeleteConfirmation={handleDeleteConfirmation}
        id={inquiry?._id}
      />
    </>
  );  
};

export default Main;
