import SummaryCard from "../../../Components/summaryCard";
import CircularProgress from "../../../Components/summaryCircularProgress";
import {
  summaryDetails,
  segments,
  TOP_CLICKED_JEWELLERY,
  TOP_CLICKED_JEWELLERY_DATA,
} from "../dashboard.constant";
import ProgressBar from "../../../Components/progressBar";
import { FaHandshake } from "react-icons/fa6";
import { LuMousePointerClick } from "react-icons/lu";
import { FaCity } from "react-icons/fa";
import { IoDiamondSharp } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import { TbMessageCircleDown } from "react-icons/tb";
import {
  useGetJewelleryFameTopCities,
  useGetMostClickedJewellery,
  useGetMostConnectedRetailer,
  useGetPerformance,
} from "../../../hooks/performance";
import { useEffect, useState } from "react";
import {  Doughnut } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

// Register all necessary components
Chart.register(...registerables);

const Performance = () => {
  const GetPerformanceDetails = useGetPerformance();
  const GetJewelleryFameTopCities = useGetJewelleryFameTopCities();
  const GetMostClickedJewellery = useGetMostClickedJewellery();
  const GetMostConnectedRetaile = useGetMostConnectedRetailer();

  const [totalSummary, setTotalSummary] = useState();
  const [topCitiesFame, setTopCitiesFame] = useState();
  const [topClickedJewellery, setTopClickedJewellery] = useState();
  const [topConnectedRetailers, setTopConnectedRetailers] = useState();
  const [activeIndex, setActiveIndex] = useState(null);
  const [showAllCities, setShowAllCities] = useState(false);
  console.log("topCitiesFame :>> ", topCitiesFame);
  useEffect(() => {
    fetchPerformance();
  }, []);

  const fetchPerformance = async () => {
    let PerDetails = GetPerformanceDetails.mutateAsync();
    let jewFameCity = GetJewelleryFameTopCities.mutateAsync();
    let mostClickedJew = GetMostClickedJewellery.mutateAsync();
    let mostConnectedRetailer = GetMostConnectedRetaile.mutateAsync();
    let res = await Promise.all([
      PerDetails,
      jewFameCity,
      mostClickedJew,
      mostConnectedRetailer,
    ]);
    console.log("object :>> ", res);
    setTotalSummary(res[0].responseData);
    setTopCitiesFame(res[1].responseData);
    const updatedTopClickedJewellery = res[2].responseData.map((item) => {
      return {
        id: item._id,
        image: item.images[0],
        jewellery_name: item.basic_info.name,
        jewecle_id: item.jewecle_id,
        net_weight: item.product_details.net_weight + " Gm",
      };
    });
    setTopClickedJewellery(updatedTopClickedJewellery);
    setTopConnectedRetailers(res[3].responseData);
  };

  const renderSummaryCards = () => {
    return summaryDetails(totalSummary).map((aSummary, index) => {
      return (
        <SummaryCard
          key={index}
          title={aSummary.title}
          subtitle={aSummary.subtitle}
          className="w-full md:w-44"
          Icon={aSummary.icon}
        />
      );
    });
  };

  const renderTableBody = () => {
    if (!topClickedJewellery || topClickedJewellery.length === 0) {
      return (
        <p className="font-bold flex justify-center items-center">
          No Data Found
        </p>
      );
    }
    return topClickedJewellery?.map((item, index) => (
      <tr
        key={item.id}
        className="hover:border hover:border-transparent hover:shadow-md hover:cursor-pointer hover:border-pink-600 "
      >
        <td
          class={`px-6 py-2 whitespace-no-wrap ${
            index === topClickedJewellery?.length - 1
              ? ""
              : "border-b border-gray-200"
          } `}
        >
          <div class="flex items-center">
            <div class="flex-shrink-0 h-10 w-10">
              <img class="h-10 w-10 rounded-full" src={item?.image} alt="" />
            </div>
          </div>
        </td>
        <td
          class={`px-6 py-2 text-center whitespace-no-wrap ${
            index === topClickedJewellery?.length - 1
              ? ""
              : "border-b border-gray-200"
          }`}
        >
          {item?.jewellery_name}
        </td>

        <td
          class={`px-6 py-2 text-center whitespace-no-wrap text-sm leading-5 ${
            index === topClickedJewellery?.length - 1
              ? ""
              : "border-b border-gray-200"
          } text-sm leading-5 `}
        >
          {item?.jewecle_id}
        </td>

        <td
          class={`px-6 py-2 text-center whitespace-no-wrap text-sm ${
            index === topClickedJewellery?.length - 1
              ? ""
              : "border-b border-gray-200"
          } text-sm leading-5 `}
        >
          {item.net_weight}
        </td>
      </tr>
    ));
  };

  // Function to prepare chart data for a Pie chart
  const preparePieChartData = (data) => {
    // Sort data by percentage in descending order
    const sortedData = data.sort((a, b) => b.percentage - a.percentage);

    // Slice the top 5 items
    const topFive = sortedData.slice(0, 5);

    // Calculate the "Others" category
    const others = sortedData.slice(5).reduce((acc, item) => acc + item.percentage, 0);

    // Prepare labels and data
    const labels = topFive.map(item => item.Product_name);
    const dataValues = topFive.map(item => item.percentage);

    // Add "Others" category if applicable
    if (others > 0) {
      labels.push('Others');
      dataValues.push(others);
    }

    return {
      labels: labels,
      datasets: [
        {
          label: 'Jewellery Fame',
          data: dataValues,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  // Function to prepare chart data for a Pie chart
  const prepareCustomPieChartData = (data) => {
    // Sort data by percentage in descending order
    const sortedData = data.sort((a, b) => b.percentage - a.percentage);

    // Slice the top 5 items
    const topFive = sortedData.slice(0, 5);

    // Calculate the "Others" category
    const others = sortedData.slice(5).reduce((acc, item) => acc + item.percentage, 0);

    // Prepare labels and data
    const labels = topFive.map(item => item.Product_name);
    const dataValues = topFive.map(item => item.percentage);

    // Add "Others" category if applicable
    if (others > 0) {
      labels.push('Others');
      dataValues.push(others);
    }

    return {
      labels: labels,
      datasets: [
        {
          label: 'Jewellery Fame',
          data: dataValues,
          backgroundColor: [
            '#FF6384', // Red
            '#36A2EB', // Blue
            '#FFCE56', // Yellow
            '#4BC0C0', // Teal
            '#9966FF', // Purple
            '#FF9F40', // Orange
          ],
          borderColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#4BC0C0',
            '#9966FF',
            '#FF9F40',
          ],
          borderWidth: 1,
          hoverOffset: activeIndex !== null ? 20 : 4, // Increase offset on hover
        },
      ],
    };
  };

  const handleChartClick = (event, elements) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      setActiveIndex(index === activeIndex ? null : index);
    }
  };

  const handleShowMore = () => {
    setShowAllCities(!showAllCities);
  };

  const sortedCities = topCitiesFame?.jewellery_city.sort((a, b) => b.percentage - a.percentage) || [];
  const citiesToDisplay = showAllCities ? sortedCities : sortedCities.slice(0, 5);

  return (
    <div className="pt-3 md:mt-12">
      {GetPerformanceDetails.isLoading ? (
        <div className="flex justify-center items-center p-4 mt-28">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      ) : (
        <>
          <div className="w-full mb-6 bg-white rounded-md px-3 py-3">
            <h3 className="text-xl">Total Counts</h3>
            <div className="md:flex flex-wrap">{renderSummaryCards()}</div>
          </div>
          <div className="w-full lg:flex gap-6">
            <div className="border border-black rounded-lg w-full lg:w-1/2 bg-white overflow-x-auto px-3 pt-3">
              <div className="flex items-center">
                <FaCity className="w-6 h-6 text-primary-pink" />
                <h3 className="text-xl ml-2">Jewellery Fame</h3>
              </div>
              <div className="w-full flex justify-center">
                <div className="w-64 h-64 sm:w-80 sm:h-80 md:w-96 md:h-96 lg:w-128 lg:h-128">
                  <Doughnut
                    data={prepareCustomPieChartData(topCitiesFame?.jewellery_fame || [])}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: 'right',
                          align: 'start',
                          labels: {
                            boxWidth: 20,
                            padding: 15,
                            font: {
                              size: 14,
                            },
                            color: '#333',
                          },
                        },
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              let label = context.label || '';
                              if (label) {
                                label += ': ';
                              }
                              if (context.parsed !== null) {
                                label += context.parsed + '%';
                              }
                              return label;
                            }
                          }
                        }
                      },
                      animation: {
                        duration: 1000, // Animation duration in milliseconds
                        easing: 'easeInOutQuad', // Easing function
                        animateScale: true, // Enable scaling animation
                        animateRotate: true, // Enable rotation animation
                      },
                    }}
                    onClick={(event, elements) => handleChartClick(event, elements)}
                  />
                </div>
              </div>
            </div>
            <div className="border border-black rounded-lg w-full lg:w-1/2 bg-white overflow-x-auto px-3 pt-3">
              <div className="flex items-center">
                <FaCity className="w-6 h-6 text-primary-pink" />
                <h3 className="text-xl ml-2">Top Cities</h3>
              </div>
              <div className="w-full">
                {citiesToDisplay.map((item) => (
                  <ProgressBar
                    key={item.Product_name}
                    percentage={item.percentage}
                    label={item.Product_name}
                  />
                ))}
              </div>
              {sortedCities.length > 10 && (
                <button
                  onClick={handleShowMore}
                  className="mt-2 text-primary-pink hover:underline"
                >
                  {showAllCities ? 'Show Less' : 'Show More'}
                </button>
              )}
            </div>
          </div>
          <div className="w-full lg:flex gap-6 mt-3">
            <div className="border border-gray w-full mb-3  rounded-lg bg-white justify-center  lg:w-1/2 px-2 md:justify-start py-2">
              <div className="flex">
                <LuMousePointerClick className="w-7 h-7 ml-2 text-primary-pink" />
                <h3 className="text-xl py-1 pb-3 ml-2">
                  Top Clicked Jewellery (Top 10)
                </h3>
              </div>
              <div className="border border-black rounded-lg w-full bg-white overflow-x-auto">
                <div className="flex flex-col">
                  <div className="overflow-x-auto ">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          {TOP_CLICKED_JEWELLERY.map((column) => (
                            <th
                              key={column.uid}
                              class="px-6 py-3 border-b font-bold border-gray-200 bg-gray-100 text-center text-xs leading-4 text-gray-500 uppercase tracking-wider"
                            >
                              {column.name}
                            </th>
                          ))}
                        </tr>
                      </thead>

                      <tbody class="bg-white max-h-[300px] overflow-y-auto ">
                        {renderTableBody()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-black rounded-lg w-full lg:w-1/2 bg-white overflow-x-auto">
              <div className="flex items-center">
                <FaHandshake className="w-8 h-8 ml-2 text-primary-pink" />
                <h3 className="text-xl py-3 pb-3 ml-3 flex">
                  Most Connected Retailers (Top 10)
                </h3>
              </div>
              {topConnectedRetailers?.map((item, index) => (
                <div className="border border-gray lg:w-10/12 mx-3 mb-3 rounded-lg mx-1 bg-white justify-center  px-2 md:justify-start py-2">
                  <div className="flex flex-col w-full">
                    <div className="flex items-center">
                      <span className="font-bold text-base">{index + 1}.</span>
                      <span className="ml-3 text-base">
                        {item.business_name}
                      </span>
                      <a
                        href={`tel:+91${item.phone}`}
                        className="ml-auto bg-blue-500 text-white px-3 py-1 rounded-md"
                      >
                        {/* <button > */}
                        <FiPhoneCall />
                        {/* </button> */}
                      </a>
                      <a
                        target="_blank"
                        href={`https://wa.me/91${item.phone}`}
                        className="ml-3 bg-green-500 text-white px-3 py-1 rounded-md"
                      >
                        <TbMessageCircleDown />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="border border-gray lg:w-10/12 mx-3 mb-3 rounded-lg mx-1 bg-white justify-center  px-2 md:justify-start py-2">
                <div className="flex flex-col w-full">
                  <div className="flex items-center">
                    <span className="font-bold text-base">2.</span>
                    <span className="ml-3 text-base">RR Jewellers</span>
                    <button className="ml-auto bg-blue-500 text-white px-3 py-1 rounded-md">
                      <FiPhoneCall />
                    </button>
                    <button className="ml-3 bg-green-500 text-white px-3 py-1 rounded-md">
                      <TbMessageCircleDown />
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Performance;