import React,{useState} from "react";
import {
  Navbar,
  Container,
  Nav,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/jeweclelogo.png";

import RegisterModal from "../registerModal"

function Header() {
  const location = useLocation();
  const token = localStorage.getItem("access_token");
  const registration_step = localStorage.getItem("registration_step");
  const [openRegisterModal, setOpenRegisterModal] = useState(false)

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("registration_step");
    window.location.href = "/";
    window.location.reload();
  };

  const onClickRegister = () => {
    setOpenRegisterModal(!openRegisterModal)
  }

  const renderAuthenticationLinks = () => {
    return token && registration_step === "3" ? (
      <>
        <Link to="/dashboard" className="outline-btn">
          My Account
        </Link>
        <button onClick={handleLogout} className="primary-btn">
          Logout
        </button>
      </>
    ) : (
      <>
        <Link to="/login" className="outline-btn">
          Login
        </Link>
        <button className="primary-btn" onClick={onClickRegister}>
          Register
        </button>
      </>
    );
  };

  return (
    <>
      <header className="main_header">
        <Navbar bg="white" expand="lg">
          <Container>
            <Navbar.Brand href="#">
            <img className="w-48" src={Logo} alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <div className="nav_bar" style={{ margin: "0 auto" }}>
                <Nav>
                  <Link
                    to="/"
                    className={
                      location?.pathname === "/" ? "active" : "inActiveLink"
                    }
                  >
                    About Us
                  </Link>
                  <Link
                    to="/how-it-works"
                    className={
                      location?.pathname === "/how-it-works"
                        ? "active"
                        : "inActiveLink"
                    }
                  >
                    How it works
                  </Link>
                  <Link
                    to="/our-solution"
                    className={
                      location?.pathname === "/our-solution"
                        ? "active"
                        : "inActiveLink"
                    }
                  >
                    Our Solution
                  </Link>
                  <Link
                    to="/dukan-to-brand"
                    className={
                      location?.pathname === "/dukan-to-brand"
                        ? "active"
                        : "inActiveLink"
                    }
                  >
                    Dukan to brand
                  </Link>
                  <Link
                    to="/pricing"
                    className={
                      location?.pathname === "/pricing"
                        ? "active"
                        : "inActiveLink"
                    }
                  >Pricing
                  </Link>
                </Nav>
              </div>
              <div className="header_action">{renderAuthenticationLinks()}</div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {openRegisterModal && (
        <RegisterModal isOpen={openRegisterModal} closeModal={onClickRegister} />
      )}
      </header>
    </>
  );
}

export default Header;
