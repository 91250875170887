import React from "react";

const StepCard = ({ icon, title, description }) => {
  return (
    <div className="col-md-4 mb-4 mb-md-0 ">
      <div className="step-box flex flex-col justify-center items-center rounded-lg">
        <img src={icon} alt="" />
        <h5 className="text-xl">{title}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default StepCard;
