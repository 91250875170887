import { useQuery, useMutation, useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import ToastFailed from '../Components/Toaster/Failed';


const API_URL = process.env.REACT_APP_BASE_URL;

export const useGetUserProfile = () => {
    const getProfile = useMutation(
        async () => {
            const response = await axios.post(`${API_URL}/user/profile`,{}, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    'accept': '*/*'
                }
            });
            return response.data;
        },
        {
            onSuccess: async (data) => {
                // navigate('/dashboard/home');
                // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
                // Handle success here
            },
            onError: (error) => {
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return getProfile;
};

export const useGetSupplierBySlug = (slug) => {
    return useQuery(
        ['supplier', slug],
        async () => {
            const response = await axios.get(`${API_URL}/user/supplier-by-slug/${slug}`, {
                headers: {
                    'accept': '*/*'
                }
            });
            return response.data;
        },
        {
            onError: (error) => {
                if (Array.isArray(error.response?.data?.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />);
                    });
                } else {
                    toast(<ToastFailed message={error.response?.data?.message || 'Error fetching supplier data'} />);
                }
            },
            enabled: !!slug
        }
    );
};

export const useGetSupplierCategoryJewellery = (supplierId, categoryId, perPage = 12, filters) => {
  const queryKey = ['supplierCategoryJewellery', supplierId, categoryId, filters];
  
  return useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1 }) => {
      if (!categoryId) return null;

      const queryParams = new URLSearchParams({
        category_id: categoryId,
        ...(filters?.subCategory?.length && { subCategory: filters.subCategory }),
        ...(filters?.weight?.length && { weight: filters.weight }),
        ...(filters?.purity?.length && { purity: filters.purity })
      });

      const response = await axios.get(
        `${API_URL}/user/supplier/${supplierId}/category-jewellery/${pageParam}/${perPage}?${queryParams}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*'
          }
        }
      );
      return response.data;
    },
    {
      enabled: !!categoryId,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage?.responseData) return undefined;
        const currentPage = pages.length;
        const totalPages = lastPage.responseData.totalPages;
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );
};
