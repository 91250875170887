import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const FullscreenImageViewer = ({ isOpen, onClose, imageUrl, images = [], onImageChange }) => {
  // Move useEffect before the conditional return
  React.useEffect(() => {
    if (!isOpen) return;

    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black z-50 flex flex-col items-center justify-center">
      {/* Close button */}
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-white z-50 p-2 hover:bg-white/10 rounded-full transition-colors"
      >
        <i className="fas fa-times text-2xl"></i>
      </button>

      {/* Main image with zoom controls */}
      <div className="flex-1 w-full flex items-center justify-center">
        <TransformWrapper
          initialScale={1}
          minScale={0.5}
          maxScale={4}
          centerOnInit={true}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <div className="absolute bottom-24 left-1/2 transform -translate-x-1/2 flex gap-4 z-50">
                <button
                  onClick={() => zoomIn()}
                  className="bg-white/10 hover:bg-white/20 text-white px-4 py-2 rounded-lg transition-colors"
                >
                  <i className="fas fa-search-plus mr-2"></i>
                  Zoom In
                </button>
                <button
                  onClick={() => zoomOut()}
                  className="bg-white/10 hover:bg-white/20 text-white px-4 py-2 rounded-lg transition-colors"
                >
                  <i className="fas fa-search-minus mr-2"></i>
                  Zoom Out
                </button>
                <button
                  onClick={() => resetTransform()}
                  className="bg-white/10 hover:bg-white/20 text-white px-4 py-2 rounded-lg transition-colors"
                >
                  <i className="fas fa-redo mr-2"></i>
                  Reset
                </button>
              </div>
              
              <TransformComponent>
                <img
                  src={imageUrl}
                  alt="Fullscreen view"
                  className="max-w-full max-h-[calc(100vh-120px)] object-contain"
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </div>

      {/* Thumbnails */}
      {images.length > 1 && (
        <div className="absolute bottom-0 w-full bg-black/50 backdrop-blur-sm p-4">
          <div className="flex justify-center gap-2 overflow-x-auto max-w-4xl mx-auto">
            {images.map((img, index) => (
              <button
                key={index}
                onClick={() => onImageChange(img)}
                className={`flex-shrink-0 relative rounded-lg overflow-hidden
                  ${imageUrl === img ? 'ring-2 ring-white' : 'opacity-50 hover:opacity-100'}`}
              >
                <img
                  src={img}
                  alt={`Thumbnail ${index + 1}`}
                  className="w-20 h-20 object-cover"
                />
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FullscreenImageViewer; 