import React, { useEffect, useState } from "react";

const FilterSidebar = ({
  filters,
  setFilters,
  isModal,
  onClose,
  selectedCategory,
  onFilterChange,
  theme = {},
}) => {
  const [localFilters, setLocalFilters] = useState(filters);
  const [isLoading, setIsLoading] = useState(false);
  const [customWeight, setCustomWeight] = useState({ from: "", to: "" });

  // Update local filters when props change
  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  // Extract unique categories from all products
  const subCategories = React.useMemo(() => {
    try {
      if (!selectedCategory?.product_category) {
        return [];
      }

      const categories = Array.isArray(selectedCategory.product_category)
        ? selectedCategory.product_category
        : [selectedCategory.product_category];

      return [
        ...new Set(
          categories.map((cat) => ({
            id: typeof cat === "string" ? cat : cat.product_category_id,
            name: typeof cat === "string" ? cat : cat.name,
          }))
        ),
      ];
    } catch (error) {
      return [];
    }
  }, [selectedCategory]);

  const handleCategoryChange = async (option) => {
    try {
      setIsLoading(true);

      if (!localFilters.subCategory) {
        return;
      }

      const newSubCategories = localFilters.subCategory.includes(option.id)
        ? localFilters.subCategory.filter((id) => id !== option.id)
        : [...localFilters.subCategory, option.id];

      // If trying to deselect the last category, select all instead
      if (newSubCategories.length === 0) {
        const allCategoryIds = subCategories.map((cat) => cat.id);

        // Update local state immediately
        setLocalFilters((prev) => ({
          ...prev,
          subCategory: allCategoryIds,
        }));

        // Notify parent
        if (onFilterChange) {
          await onFilterChange("subCategory", {
            id: allCategoryIds,
            selectAll: true,
            currentSelection: allCategoryIds,
          });
        }
      } else {
        // Update local state immediately
        setLocalFilters((prev) => ({
          ...prev,
          subCategory: newSubCategories,
        }));

        // Notify parent
        if (onFilterChange) {
          await onFilterChange("subCategory", {
            id: option.id,
            currentSelection: newSubCategories,
            isDeselect: localFilters.subCategory.includes(option.id),
          });
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  // Render checkboxes with loading state
  const renderCategoryCheckbox = (option) => (
    <label
      key={option.id}
      className="flex items-center gap-3 cursor-pointer group"
    >
      <input
        type="checkbox"
        checked={localFilters.subCategory?.includes(option.id)}
        onChange={() => !isLoading && handleCategoryChange(option)}
        disabled={isLoading}
        className={`w-4 h-4 rounded focus:ring-2 transition-colors ${
          isLoading ? "opacity-50" : ""
        }`}
        style={{
          borderColor: theme.secondary,
          backgroundColor: localFilters.subCategory?.includes(option.id)
            ? theme.secondary
            : "white",
        }}
      />
      <span
        className={`text-sm text-gray-600 group-hover:text-gray-900 transition-colors ${
          isLoading ? "opacity-50" : ""
        }`}
      >
        {option.name}
      </span>
    </label>
  );

  const filterOptions = {
    subCategory: subCategories,
    weight: ["0-5g", "5-10g", "10-20g", "20g+"],
    purity: ["18", "20", "22"],
  };

  const handleCustomWeightSubmit = (e) => {
    e.preventDefault();
    if (customWeight.from && customWeight.to) {
      const weightRange = `${customWeight.from}-${customWeight.to}g`;

      // Check if this range already exists in filters
      if (!filters.weight.includes(weightRange)) {
        if (onFilterChange) {
          onFilterChange("weight", weightRange);
        } else {
          setFilters((prev) => ({
            ...prev,
            weight: [...prev.weight, weightRange],
          }));
        }
      }

      // Reset inputs
      setCustomWeight({ from: "", to: "" });
    }
  };

  const handleFilterChange = async (filterType, value) => {
    try {
      if (filterType === "subCategory") {
        setFilters((prev) => ({
          ...prev,
          subCategory: value.currentSelection,
        }));
        // If you need to fetch data, implement the fetchData function or remove this line
        // await fetchData(value.currentSelection);
      }
    } catch (error) {}
  };

  return (
    <div className={`${isModal ? "h-full flex flex-col" : ""}`}>
      {/* Scrollable content area */}
      <div
        className={`
        ${isModal ? "flex-1 overflow-y-auto" : ""} 
      `}
      >
        <div className="px-4 pb-20">
          <div className="space-y-4 sm:space-y-6">
            {/* Categories Section */}
            <div className="border-b border-gray-200/60 pb-4 sm:pb-6">
              <h3
                className="text-sm font-semibold mb-3 sm:mb-4"
                style={{ color: theme.secondary }}
              >
                Categories {isLoading && "(Updating...)"}
              </h3>
              <div className="space-y-2 sm:space-y-3">
                {subCategories.length > 0 ? (
                  subCategories.map(renderCategoryCheckbox)
                ) : (
                  <p className="text-sm text-gray-500">
                    No categories available
                  </p>
                )}
              </div>
            </div>

            {/* Weight Section */}
            <div className="border-b border-gray-200/60 pb-4 sm:pb-6">
              <h3
                className="text-sm font-semibold mb-3 sm:mb-4"
                style={{ color: theme.secondary }}
              >
                Weight
              </h3>

              {/* Custom Weight Range Input */}
              <form onSubmit={handleCustomWeightSubmit} className="mb-4">
                <div className="flex flex-wrap items-center gap-2">
                  <div className="flex items-center gap-2 min-w-[160px]">
                    <input
                      type="number"
                      value={customWeight.from}
                      onChange={(e) =>
                        setCustomWeight((prev) => ({
                          ...prev,
                          from: e.target.value,
                        }))
                      }
                      placeholder="From"
                      min="0"
                      step="0.1"
                      className="w-16 sm:w-20 px-2 py-1.5 border rounded-lg text-sm focus:outline-none focus:ring-1"
                      style={{
                        borderColor: `${theme.secondary}40`,
                        focusRing: theme.secondary,
                      }}
                    />
                    <span className="text-gray-500">-</span>
                    <input
                      type="number"
                      value={customWeight.to}
                      onChange={(e) =>
                        setCustomWeight((prev) => ({
                          ...prev,
                          to: e.target.value,
                        }))
                      }
                      placeholder="To"
                      min="0"
                      step="0.1"
                      className="w-16 sm:w-20 px-2 py-1.5 border rounded-lg text-sm focus:outline-none focus:ring-1"
                      style={{
                        borderColor: `${theme.secondary}40`,
                        focusRing: theme.secondary,
                      }}
                    />
                    <span className="text-gray-500">g</span>
                  </div>
                  <button
                    type="submit"
                    disabled={
                      !customWeight.from ||
                      !customWeight.to ||
                      parseFloat(customWeight.from) >=
                        parseFloat(customWeight.to)
                    }
                    className="px-3 py-1.5 rounded-lg text-sm text-white transition-opacity disabled:opacity-50 disabled:cursor-not-allowed hover:opacity-90"
                    style={{ backgroundColor: theme.secondary }}
                  >
                    Add
                  </button>
                </div>
              </form>

              {/* Weight Ranges */}
              <div className="space-y-2 sm:space-y-3">
                {[
                  ...filterOptions.weight,
                  ...filters.weight.filter(
                    (w) => !filterOptions.weight.includes(w)
                  ),
                ].map((option) => (
                  <label
                    key={option}
                    className="flex items-center gap-3 cursor-pointer group"
                  >
                    <input
                      type="checkbox"
                      checked={filters.weight.includes(option)}
                      onChange={() => onFilterChange("weight", option)}
                      className="w-4 h-4 rounded focus:ring-2 transition-colors"
                      style={{
                        borderColor: theme.secondary,
                        backgroundColor: filters.weight.includes(option)
                          ? theme.secondary
                          : "white",
                      }}
                    />
                    <span className="text-sm text-gray-600 group-hover:text-gray-900 transition-colors">
                      {option}
                    </span>
                    {!filterOptions.weight.includes(option) && (
                      <button
                        onClick={() =>
                          setFilters((prev) => ({
                            ...prev,
                            weight: prev.weight.filter((w) => w !== option),
                          }))
                        }
                        className="ml-auto p-1 rounded-full hover:bg-gray-100 text-gray-400 hover:text-red-500 transition-colors"
                      >
                        <i className="fas fa-times text-xs"></i>
                      </button>
                    )}
                  </label>
                ))}
              </div>
            </div>

            {/* Purity Section */}
            <div className="pb-4 sm:pb-6">
              <h3
                className="text-sm font-semibold mb-3 sm:mb-4"
                style={{ color: theme.secondary }}
              >
                Purity
              </h3>
              <div className="space-y-2 sm:space-y-3">
                {filterOptions.purity.map((option) => (
                  <label
                    key={option}
                    className="flex items-center gap-3 cursor-pointer group"
                  >
                    <input
                      type="checkbox"
                      checked={filters.purity.includes(option)}
                      onChange={() => onFilterChange("purity", option)}
                      className="w-4 h-4 rounded focus:ring-2 transition-colors"
                      style={{
                        borderColor: theme.secondary,
                        backgroundColor: filters.purity.includes(option)
                          ? theme.secondary
                          : "white",
                      }}
                    />
                    <span className="text-sm text-gray-600 group-hover:text-gray-900 transition-colors">
                      {option}K
                    </span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterSidebar;
