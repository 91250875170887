import React from "react";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { Container } from "react-bootstrap";

const Pricing = () => {
  const pricingPlans = [
    {
      items: 100,
      singlePrice: 9999,
      monthlyRent: 999,
      whatsappMarketing: false,
      catalogPromotion: false,
    },
    {
      items: 250,
      singlePrice: 9999,
      monthlyRent: 1999,
      whatsappMarketing: true,
      catalogPromotion: false,
    },
    {
      items: 500,
      singlePrice: 9999,
      monthlyRent: 2499,
      whatsappMarketing: true,
      catalogPromotion: true,
    },
    {
      items: 1000,
      singlePrice: 9999,
      monthlyRent: 3499,
      whatsappMarketing: true,
      catalogPromotion: true,
    },
  ];

  return (
    <Container>
      <h1 className="flex justify-center">
        <span className="text-gold font-bold text-4xl pink-text">Pricing</span>
      </h1>
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {pricingPlans.map((plan, index) => (
            <div
              key={index}
              className="bg-white text-gray-800 rounded-lg p-6 shadow-xl flex flex-col items-center border-2 border-jw-golden transform transition-transform duration-300 hover:scale-105"
            >
              <h3 className="text-2xl font-bold mb-4 text-gray-500">{plan.items} ITEMS</h3>
              <hr className="w-full border-t-2 border-gray-300 mb-4" />
              <p className="text-gray-600">One-Time Payment</p>
              <h4 className="text-3xl font-bold mb-2 text-gold">₹{plan.singlePrice}/-</h4>
              <p className="text-gray-600">Monthly Subscription</p>
              <h4 className="text-3xl font-bold mb-4 text-gold">₹{plan.monthlyRent}/-</h4>
              <hr className="w-full border-t-2 border-gray-300 mb-4" />

              <div className="flex items-center justify-start mb-2 w-full space-x-3">
                {plan.whatsappMarketing ? (
                  <FaCheck className="text-green-500 w-6 h-6" />
                ) : (
                  <RxCross2 className="text-red-500 w-6 h-6" />
                )}
                <p className="text-gray-600 text-sm font-medium">
                  FREE WHATSAPP GROUP MARKETING
                </p>
              </div>

              <div className="flex items-center justify-start mb-4 w-full space-x-3">
                {plan.catalogPromotion ? (
                  <FaCheck className="text-green-500 w-6 h-6" />
                ) : (
                  <RxCross2 className="text-red-500 w-6 h-6" />
                )}
                <p className="text-gray-600 text-sm font-medium">
                  FREE CATALOGUES PROMOTION
                </p>
              </div>

              {/* Buy Now Button */}
              {/* <button className="bg-jw-golden text-white py-2 px-4 rounded-full font-bold mt-auto hover:bg-gold-dark transition-colors duration-300">
                Contact Us
              </button> */}
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Pricing;
