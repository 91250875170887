import React, { useEffect, useState, useContext } from "react";
import SearchIcon from "../../assets/images/icons/search.svg";
import FileUpload from "../../assets/images/icons/file_upload.svg";
import Loader from "../../Components/Loader/loaderv2";
import Select from "react-select";
import HeaderTabs from "../../Components/Dashboard/HeaderTabs";
import { Link } from "react-router-dom";
import {
  useGetJewellery,
  useGetProductById,
  useDeleteJewellery,
} from "../../hooks/uploadJewellery";
import { useGetCategorySubcategory } from "../../hooks/common";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import AddOrderModal from "./addOrderModal";
import { FaEye } from "react-icons/fa";
import { MdOutlineModeEdit } from "react-icons/md";
import { MdOutlineGridView } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import ViewProductModal from "../../Components/viewProductModal";
import { RiChatUploadLine } from "react-icons/ri";
import DeleteConfirmationModal from "../../Components/DeleteConfirmationModal";
import CategoriesDropdown from "../../Components/Dashboard/CategoriesDropdown";
import { contextData } from "../../context/ContextState";
import { toast } from 'react-toastify';
import Toast from '../../Components/Toaster/Successed';
import ToastFailed from '../../Components/Toaster/Failed';

export default function ProductList() {
  const { businessName } = useContext(contextData);

  console.log("Business Name:", businessName);

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      // padding: "4px",
      border: "none",
      boxShadow: "none",
    }),
  };

  const options = [
    { value: 2, label: "Asceding By Weight" },
    { value: 3, label: "Desceding By Weight" },
  ];
  const getCategories = useGetCategorySubcategory();
  const GetJewelleryMutation = useGetJewellery();
  const deleteJewelleryById = useDeleteJewellery();
  const GetProductByIdMutation = useGetProductById();
  const [isViewProductModal, setIsViewProductModal] = useState();
  const [showGallery, setShowGallery] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [jewellery, setJewellery] = useState([]);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  const [selectedTab, setselectedTab] = useState({
    title: "All Items",
    value: 0,
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [addOrderModal, setAddOrderModalModal] = useState(false);
  const [addOrderData, setAddOrderData] = useState();
  const [tabs, setTabs] = useState([
    { title: "All Items", count: 0, value: 0 },
    { title: "In Progress", count: 0, value: 1 },
    { title: "Verified", count: 0, value: 2 },
    { title: "Errors", count: 0, value: 3 },
    { title: "Draft", count: 0, value: 4 },
  ]);
  const [searchValue, setSearchValue] = useState("");
  const [id, setId] = useState("");
  const [images, setImages] = useState([
    {
      original: "",
      thumbnail: "",
    },
  ]);
  const [categoriesData, setCategoriesData] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDoubleDropdownOpen, setIsDoubleDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  const impersonate = localStorage.getItem("impersonate");
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleDoubleDropdown = (data) => {
    setIsDoubleDropdownOpen(!isDoubleDropdownOpen);
    setSelectedItem(data);
  };
  const handleSelectedSubcategory = async (data) => {
    setSelectedSubcategory(data);
    setIsDoubleDropdownOpen(false);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCategories.mutateAsync();
        if (!data) {
          return;
        }
        setCategoriesData(data.responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchJewellery(currentPage, perPage);
  }, [
    currentPage,
    perPage,
    selectedTab,
    searchValue,
    selectedOption,
    selectedSubcategory,
  ]);

  const fetchJewellery = async (pageNo, perPage) => {
    setPage(pageNo);
    setPerPage(perPage);
    setCurrentPage(pageNo);
    const data = await GetJewelleryMutation.mutateAsync({
      page: pageNo,
      perPage: perPage,
      state: selectedTab.value,
      search: searchValue,
      weight: selectedOption?.value,
      jewellery_type: selectedItem?._id,
      ...(selectedSubcategory?._id && {
        jewellery_sub_type: selectedSubcategory?._id,
      }),
    });
    let tampTabs = tabs;
    let finalTabs = tampTabs.map((tab) => {
      let count;
      if (tab.value === selectedTab.value) {
        if (tab.title === "All Items") {
          count = data.responseData.count;
        } else {
          count = data.responseData.count;
        }
      } else {
        switch (tab.value) {
          case 0:
            count =
              data.responseData.counts.INPROGRESS +
              data.responseData.counts.PASS +
              data.responseData.counts.ERROR +
              data.responseData.counts.DRAFT;
            break;
          case 1:
            count = data.responseData.counts.INPROGRESS;
            break;
          case 2:
            count = data.responseData.counts.PASS;
            break;
          case 3:
            count = data.responseData.counts.ERROR;
            break;
          case 4:
            count = data.responseData.counts.DRAFT;
            break;
          default:
            count = 0;
        }
      }
      return {
        ...tab,
        count: count,
      };
    });
    setTabs(finalTabs);
    switch (selectedTab.title) {
      case "All Items":
        setCount(data?.responseData?.count);
        break;
      case "In Progress":
        setCount(data?.responseData?.counts?.INPROGRESS);
        break;
      case "Verified":
        setCount(data?.responseData?.counts?.PASS);
        break;
      case "Draft":
        setCount(data?.responseData?.counts?.DRAFT);
        break;
      case "Errors":
        setCount(data?.responseData?.counts?.ERROR);
        break;
      default:
        setCount(0);
        break;
    }
    setJewellery(data?.responseData?.items);
  };

  // Function to open the gallery on image click
  const openGallery = (index, images) => {
    setShowGallery(true);
    setCurrentIndex(index);
    let arr = [];
    images.map((image) => {
      arr.push({
        original: image,
        thumbnail: image,
      });
    });
    setImages(arr);
  };

  // Function to close the gallery
  const closeGallery = () => {
    setShowGallery(false);
  };

  const handleSelectTab = (title, value) => {
    setselectedTab({ title: title, value: value });
  };

  const handleSearch = async (e) => {
    setSearchValue(e.target.value);
  };

  const renderCreatedDate = (date) => (
    <div className="flex flex-row gap-1.5 items-center justify-end relative mt-1 mr-4">
      <svg
        className="shrink-0 relative overflow-visible"
        style={{}}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5ZM10.5 16.1925L6.75 12.4425L7.9425 11.25L10.5 13.8075L16.0575 8.25L17.2545 9.4395L10.5 16.1925Z"
          fill="#198F05"
        />
      </svg>

      <div
        className="text-[#198f05] text-left relative"
        style={{ font: "400 14px 'Segoe UI', sans-serif" }}
      >
        Added 23/02/2023
      </div>
    </div>
  );

  const renderErrorMessage = (error) => (
    <div className="flex flex-row gap-1.5 items-start justify-end relative mt-1 mr-4">
      <svg
        className="shrink-0 relative overflow-visible"
        style={{}}
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9163 11.9166H10.083V6.41659H11.9163M11.9163 15.5833H10.083V13.7499H11.9163M10.9997 1.83325C9.79589 1.83325 8.60389 2.07035 7.49174 2.53102C6.37959 2.99169 5.36907 3.6669 4.51786 4.51811C2.79878 6.23719 1.83301 8.56877 1.83301 10.9999C1.83301 13.4311 2.79878 15.7626 4.51786 17.4817C5.36907 18.3329 6.37959 19.0081 7.49174 19.4688C8.60389 19.9295 9.79589 20.1666 10.9997 20.1666C13.4308 20.1666 15.7624 19.2008 17.4815 17.4817C19.2006 15.7626 20.1663 13.4311 20.1663 10.9999C20.1663 9.79613 19.9292 8.60414 19.4686 7.49199C19.0079 6.37984 18.3327 5.36931 17.4815 4.51811C16.6303 3.6669 15.6198 2.99169 14.5076 2.53102C13.3955 2.07035 12.2035 1.83325 10.9997 1.83325Z"
          fill="#FF3C47"
        />
      </svg>

      <div
        className="text-red-error text-left relative"
        style={{ font: "400 14px 'Segoe UI', sans-serif" }}
      >
        jewelry image background error
      </div>
    </div>
  );

  const renderInProgress = () => (
    <div className="flex flex-row gap-1.5 items-center justify-end relative mt-1 mr-4">
      <svg
        className="shrink-0 relative overflow-visible"
        style={{}}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9998 2.03003C17.7298 2.50003 21.4998 6.25003 21.9498 11C22.0816 12.3057 21.9545 13.6245 21.5757 14.881C21.197 16.1374 20.5741 17.3068 19.7427 18.3221C18.9113 19.3374 17.8878 20.1787 16.7307 20.7978C15.5736 21.417 14.3058 21.8017 12.9998 21.93V19.93C16.6398 19.5 19.4998 16.61 19.9598 12.97C20.0893 11.9272 20.012 10.8691 19.7322 9.85616C19.4524 8.84326 18.9757 7.89545 18.3292 7.06697C17.6828 6.23849 16.8794 5.54559 15.9649 5.02791C15.0504 4.51024 14.0428 4.17794 12.9998 4.05003V2.03003ZM10.9998 2.06003V4.06003C9.5698 4.26003 8.2198 4.84003 7.0998 5.74003L5.6698 4.26003C7.18651 3.01022 9.04312 2.24389 10.9998 2.06003ZM4.2598 5.67003L5.6898 7.10003C4.80379 8.22973 4.2374 9.57663 4.0498 11H2.0498C2.2498 9.04003 2.9998 7.19003 4.2598 5.67003ZM2.0598 13H4.0598C4.2398 14.42 4.8098 15.77 5.6898 16.9L4.2698 18.33C3.02748 16.8079 2.25908 14.9547 2.0598 13ZM7.0998 18.37C8.2298 19.25 9.5798 19.82 10.9998 20V22C9.0398 21.79 7.1798 21 5.6698 19.74L7.0998 18.37ZM11.9998 7.50003L7.4998 12H10.9998V16H12.9998V12H16.4998L11.9998 7.50003Z"
          fill="#333333"
        />
      </svg>

      <div
        className="text-[#333333] text-left relative"
        style={{ font: "400 14px 'Segoe UI', sans-serif" }}
      >
        IN PROGRESS Uploaded within 24 hours
      </div>
    </div>
  );

  const handleEdit = async (data) => {
    setIsViewProductModal(true);
    const producDetails = await GetProductByIdMutation.mutateAsync({
      id: data.product_id,
    });
    setProductDetails({
      data: [
        {
          selectedProduct: {
            product_id: data.product_id,
            subCategoryId: data.product_category._id,
            category: data.basic_info.name,
            length: producDetails.responseData.length,
            lengthValue: producDetails.responseData.lengthValue,
          },
          selectedImages: data.images.map((item) => {
            return {
              name: item,
              url: item,
              uploadedUrl: item.split("/").slice(-2).join("/"),
            };
          }),
          details: [
            {
              description: data.basic_info.description,
              status: data.product_details.status,
              tag_id: data.tag_id,
              huid: data.product_details.huid,
              gross_weight: data.product_details.gross_weight,
              net_weight: data.product_details.net_weight,
              width: data.product_details.width,
              length: data.product_details.length,
              breadth: data.product_details.breadth,
              measurement_in: data.product_details.measurement_in,
              purity: data.product_details.purity,
              size: data.product_details.size ? data.product_details.size : "",
              color: data.product_details.color,
              days: data.product_details.days,
              is_customize: data.is_customize,
              wastage: data.wastage_details.wastage,
              discount: data.wastage_details.discount,
              other_amt: data.wastage_details.other_amt,
              total_labour: data.wastage_details.total_labour,
              final_wastage: data.wastage_details.final_wastage,
              _id: data._id,
            },
          ],
        },
      ],
    });
    // navigate("/dashboard/jewellery-info", {
    //   state: {
    //     data: [
    //       {
    //         selectedProduct: {
    //           product_id: data.product_id,
    //           subCategoryId: data.product_category._id,
    //           category: data.basic_info.name,
    //           length: producDetails.responseData.length,
    //           lengthValue: producDetails.responseData.lengthValue,
    //         },
    //         selectedImages: data.images.map((item) => {
    //           return {
    //             name: item,
    //             url: item,
    //             uploadedUrl: item.split("/").slice(-2).join("/"),
    //           };
    //         }),
    //         details: [
    //           {
    //             description: data.basic_info.description,
    //             status: data.product_details.status,
    //             tag_id: data.tag_id,
    //             huid: data.product_details.huid,
    //             gross_weight: data.product_details.gross_weight,
    //             net_weight: data.product_details.net_weight,
    //             width: data.product_details.width,
    //             length: data.product_details.length,
    //             breadth: data.product_details.breadth,
    //             measurement_in: data.product_details.measurement_in,
    //             purity: data.product_details.purity,
    //             size: data.product_details.size,
    //             color: data.product_details.color,
    //             days: data.product_details.days,
    //             is_customize: data.is_customize,
    //             wastage: data.wastage_details.wastage,
    //             discount: data.wastage_details.discount,
    //             other_amt: data.wastage_details.other_amt,
    //             total_labour: data.wastage_details.total_labour,
    //             final_wastage: data.wastage_details.final_wastage,
    //             _id: data._id,
    //           },
    //         ],
    //       },
    //     ],
    //   },
    // });
  };

  const handleDelete = async (data) => {
    setIsDeleteModalOpen(true);
    setId(data._id);
  };
  const deleteConfirmation = async () => {
    const deleteProduct = await deleteJewelleryById.mutateAsync({
      id,
    });
    fetchJewellery(currentPage, perPage);
    console.log("deleteProduct", deleteProduct);
    setIsDeleteModalOpen(false);
  };

  const handleInfo = async (data) => {
    setIsViewProductModal(true);
    const producDetails = await GetProductByIdMutation.mutateAsync({
      id: data.product_id,
    });
    setProductDetails({
      data: [
        {
          selectedProduct: {
            product_id: data.product_id,
            subcategory: data.product_category,
            category: data.basic_info.name,
            length: producDetails.responseData.length,
            lengthValue: producDetails.responseData.lengthValue,
          },
          selectedImages: data.images,
          details: [
            {
              description: data.basic_info.description,
              status: data.product_details.status,
              tag_id: data.tag_id,
              huid: data.product_details.huid,
              gross_weight: data.product_details.gross_weight,
              net_weight: data.product_details.net_weight,
              width: data.product_details.width,
              length: data.product_details.length,
              breadth: data.product_details.breadth,
              measurement_in: data.product_details.measurement_in,
              purity: data.product_details.purity,
              size: data.product_details.size ? data.product_details.size : "",
              color: data.product_details.color,
              days: data.product_details.days,
              is_customize: data.is_customize,
              wastage: data.wastage_details.wastage,
              discount: data.wastage_details.discount,
              other_amt: data.wastage_details.other_amt,
              total_labour: data.wastage_details.total_labour,
              final_wastage: data.wastage_details.final_wastage,
              _id: data._id,
            },
          ],
        },
      ],
      isView: true,
    });
    // navigate("/dashboard/jewellery-info", {
    //   state: {
    //     data: [
    //       {
    //         selectedProduct: {
    //           product_id: data.product_id,
    //           subcategory: data.product_category,
    //           category: data.basic_info.name,
    //           length: producDetails.responseData.length,
    //           lengthValue: producDetails.responseData.lengthValue,
    //         },
    //         selectedImages: data.images,
    //         details: [
    //           {
    //             description: data.basic_info.description,
    //             status: data.product_details.status,
    //             tag_id: data.tag_id,
    //             huid: data.product_details.huid,
    //             gross_weight: data.product_details.gross_weight,
    //             net_weight: data.product_details.net_weight,
    //             width: data.product_details.width,
    //             length: data.product_details.length,
    //             breadth: data.product_details.breadth,
    //             measurement_in: data.product_details.measurement_in,
    //             purity: data.product_details.purity,
    //             size: data.product_details.size,
    //             color: data.product_details.color,
    //             days: data.product_details.days,
    //             is_customize: data.is_customize,
    //             wastage: data.wastage_details.wastage,
    //             discount: data.wastage_details.discount,
    //             other_amt: data.wastage_details.other_amt,
    //             total_labour: data.wastage_details.total_labour,
    //             final_wastage: data.wastage_details.final_wastage,
    //             _id: data._id,
    //           },
    //         ],
    //       },
    //     ],
    //     isView: true,
    //   },
    // });
  };
  const handleAddOrder = async (data) => {
    setAddOrderModalModal(true);
    setAddOrderData(data);
  };

  function capitalizeFirstLetter(string) {
    if (!string) {
      return;
    }
    return (
      string?.charAt(0).toUpperCase() + string.slice(1).toLowerCase() || "-"
    );
  }
  const getBadgeColor = (status) => {
    switch (status) {
      case "INPROGRESS":
        return "bg-amber-300 text-amber-800";
      case "PASS":
        return "bg-green-300 text-green-800";
      case "FAIL":
        return "bg-red-300 text-red-800";
      case "DRAFT":
        return "bg-blue-300 text-blue-800";
      default:
        return "bg-gray-300 text-gray-800";
    }
  };
  const renderTableBody = () => {
    if (!jewellery || jewellery.length === 0) {
      return (
        <tr className="flex justify-center items-center bg-am">
          <td
            colSpan="1"
            className="text-center font-bold whitespace-nowrap p-3"
          >
            No Data Found
          </td>
        </tr>
      );
    }

    return jewellery.map((item, index) => (
      <tr key={item.id} className="">
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
          <div class="flex items-center">
            <div class="flex-shrink-0 h-10 w-10">
              <img
                class="h-10 w-10 rounded-full"
                src={item?.images && item?.images[0]}
                alt={item?.product_name}
                onClick={() => openGallery(index, item?.images)}
              />
            </div>
          </div>
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {capitalizeFirstLetter(item?.product_name) || "-"}{" "}
          {capitalizeFirstLetter(item?.product_category?.name)}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
          <p
            className={`inline-block px-2 py-1 font-medium rounded-full text-sm ${
              item?.status && item?.status !== "undefined"
                ? getBadgeColor(item?.status)
                : ""
            }`}
          >
            {item?.status && item?.status !== "undefined" ? item?.status : "-"}
          </p>
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.tag_id}
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.jewecle_id}
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.product_details.purity}K
        </td>

        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.product_details.net_weight} GRMS
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.product_details.gross_weight} GRMS
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-primary-pink font-bold text-sm">
          {item.wastage_details.wastage} %
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.product_details.size
            ? item.product_details.size +
              " " +
              item.product_details.measurement_in
            : ""}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
          <div className="flex gap-3">
            <div
              onClick={() => handleInfo(item)}
              className="cursor-pointer text-blue-500 hover:text-blue-700"
            >
              <FaEye className="w-5 h-5" />
            </div>
            <div
              onClick={() => handleEdit(item)}
              className="cursor-pointer text-stone-500 hover:text-stone-700"
            >
              <MdOutlineModeEdit className="w-5 h-5" />
            </div>
            <div
              className="cursor-pointer text-red-500 hover:text-red-700"
              onClick={() => handleDelete(item)}
            >
              <MdDeleteOutline className="w-5 h-5" />
            </div>
            {(selectedTab.title === "Verified" || item.status === "PASS") && (
              <div
                onClick={() => handleAddOrder(item)}
                className="cursor-pointer text-green-600 hover:text-green-700"
              >
                <AiOutlineDeliveredProcedure className="w-5 h-5" />
              </div>
            )}
          </div>
        </td>
      </tr>
    ));
  };

  const getPagesCount = () => {
    const totalPages = Math.ceil(count / perPage);
    return [...Array(totalPages).keys()].map((n) => n + 1);
  };

  const totalPages = getPagesCount().length;

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      setPage((prevPage) => prevPage + 1);
      if (currentPage - offset >= 4) {
        // If we're at the last displayed page button
        setOffset((prevOffset) => prevOffset + 5);
      }
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      setPage((prevPage) => prevPage - 1);
      if (currentPage - offset === 1) {
        // If we're at the first displayed page button
        setOffset((prevOffset) => prevOffset - 5);
      }
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setPage(pageNumber);
    fetchJewellery(pageNumber, perPage);
    setCurrentPage(pageNumber);
  };

  const handlePePage = (e) => {
    setPerPage(Number(e.target.value));
    setPage(1);
    setCurrentPage(1);
  };

  function closeModal() {
    setAddOrderModalModal(false);
  }
  const getDisplayedPages = () => {
    const totalPagesToShow = 5; // Adjust the number of pages to show
    const startPage = Math.max(
      1,
      currentPage - Math.floor(totalPagesToShow / 2)
    );
    const endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  const renderDeleteModal = () => {
    return (
      isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          closeModal={() => setIsDeleteModalOpen(false)}
          handleDeleteConfirmation={deleteConfirmation}
          title="Are you sure want to delete jewellery?"
        />
      )
    );
  };

  const renderCategorySubcategorySection = () => {
    return (
      <ul>
        {/* {products.map((item, index) => (
          <li className="gender-type" key={index}>
            <span>
              <button
                className={`${selectedProduct.title && "cursor-not-allowed"}`}
                onClick={() => filterGendderWise(item.gender, null, index)}
              >
                <span
                  className={`${
                    item.isTag && "text-jw-primary text-xl"
                  } text-lg font-semibold`}
                >
                  {item?.gender}
                </span>
                <img src={upload_option_arrow} alt="" />
              </button>
              {item.isTag && (
                <div className="flex gap-2">
                  <span className="font-bold text-sm">
                    {selectedProduct.title}
                  </span>
                  <img
                    className="cursor-pointer"
                    width={10}
                    onClick={() => handleRemoveisTag()}
                    src={option_ic_close_arrow}
                    alt=""
                  />
                </div>
              )}
            </span>
          </li>
        ))} */}
      </ul>
    );
  };

  const handleCopyLink = () => {
    const formattedBusinessName = businessName.replace(/\s+/g, "-");
    const baseUrl = `${window.location.origin}/profile/${formattedBusinessName}`;
    const categoryParam = selectedItem ? `category=${selectedItem._id}` : "";
    const subcategoryParam = selectedSubcategory && selectedSubcategory.name !== "All" 
      ? `subCategory=${selectedSubcategory._id}` 
      : "";
    const queryParams = [categoryParam, subcategoryParam]
      .filter(Boolean)
      .join("&");
    const fullUrl = `${baseUrl}?${queryParams}`;

    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        toast(<Toast message="Link copied to clipboard!" />);
      })
      .catch((err) => {
        toast(<ToastFailed message="Failed to copy link" />);
        console.error("Failed to copy link: ", err);
      });
  };

  return (
    <div>
      {showGallery ? (
        <>
          <div className="relative h-screen">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 absolute top-8 left-8 cursor-pointer text-white z-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#F64668"
              onClick={closeGallery}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <ImageGallery
              items={images}
              showFullscreenButton={true}
              showPlayButton={false}
              startIndex={currentIndex}
              showIndex={true}
              showNav={true}
              additionalClass="image-gallery" // Optional: Add a custom CSS class to ImageGallery
              onRequestClose={closeGallery}
            />
          </div>
        </>
      ) : (
        <div className="common-layout">
          <div className="flex flex-col md:flex-row justify-between items-start mt-10">
            <div className="flex justify-start items-center mb-4">
              <h1 className="text-lg md:text-2xl ml-2 font-bold">
                Stock Management
              </h1>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-3 mb-4 flex-wrap">
            {/* Search Bar */}
            <div className="relative w-full sm:w-[320px]">
              <img
                src={SearchIcon}
                alt="search"
                className="absolute top-1/2 transform -translate-y-1/2 left-3 w-4 h-4"
              />
              <input
                type="text"
                className="w-full pl-10 pr-4 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-primary-pink focus:border-transparent"
                placeholder="Search products..."
                onChange={handleSearch}
              />
            </div>

            {/* Container for Sort and Category - Only grouped on mobile */}
            <div className="flex gap-3 w-full sm:w-auto sm:contents">
              {/* Sort Dropdown */}
              <div className="w-1/2 sm:w-[180px] order-2 sm:order-none">
                <Select
                  className="text-sm"
                  value={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  styles={customStyles}
                  placeholder="Sort by..."
                />
              </div>

              {/* Category Dropdown */}
              <div className="w-1/2 sm:w-[200px] relative order-1 sm:order-none">
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="w-full bg-white border border-gray-200 rounded-lg px-3 py-2 text-sm text-gray-700 flex items-center justify-between hover:bg-gray-50 focus:ring-2 focus:ring-primary-pink focus:border-transparent"
                >
                  <span className="truncate">
                    {selectedItem
                      ? `${selectedItem?.name}${selectedSubcategory ? ` - ${selectedSubcategory?.name}` : ''}`
                      : "Select category"}
                  </span>
                  <svg className="w-4 h-4 ml-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                {isDropdownOpen && (
                  <div
                    className="z-10 absolute right-0 mt-2 w-44 rounded-lg shadow-lg bg-white divide-y divide-gray-100"
                    id="multi-dropdown"
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="multiLevelDropdownButton"
                    >
                      {categoriesData &&
                        categoriesData.map((aData, index) => {
                          return (
                            <li key={index}>
                              <button
                                type="button"
                                onClick={() => toggleDoubleDropdown(aData)}
                                className="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100"
                                id="doubleDropdownButton"
                                data-dropdown-toggle="doubleDropdown"
                                data-dropdown-placement="right-start"
                              >
                                {aData.name}
                                <svg
                                  className="w-2.5 h-2.5 ms-3 rtl:rotate-180"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 6 10"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 9 4-4-4-4"
                                  />
                                </svg>
                              </button>
                              {isDoubleDropdownOpen &&
                                selectedItem &&
                                selectedItem === aData && (
                                  <div
                                    className="z-10 absolute right-0 mt-0 top-0 left-full w-44 rounded-lg shadow-lg bg-white divide-y divide-gray-100"
                                    id="doubleDropdown"
                                  >
                                    <ul
                                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                                      aria-labelledby="doubleDropdownButton"
                                    >
                                      <li
                                        key={index}
                                        onClick={() =>
                                          handleSelectedSubcategory({
                                            name: "All",
                                          })
                                        }
                                      >
                                        <a
                                          href="#"
                                          className="block text-black px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                        >
                                          All
                                        </a>
                                      </li>
                                      {aData.jewelleryType.map(
                                        (jType, index) => {
                                          return (
                                            <li
                                              key={index}
                                              onClick={() =>
                                                handleSelectedSubcategory(jType)
                                              }
                                            >
                                              <a
                                                href="#"
                                                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                              >
                                                {jType.name}
                                              </a>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                )}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* Copy Link Button */}
            <div className="w-full sm:ml-auto sm:w-auto order-last">
              <button
                type="button"
                onClick={handleCopyLink}
                className="w-full sm:w-auto px-4 py-2 bg-white border border-gray-200 rounded-lg text-sm text-gray-700 flex items-center justify-center gap-2 hover:bg-gray-50 focus:ring-2 focus:ring-primary-pink focus:border-transparent"
              >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                </svg>
                <span className="inline">Copy Link</span>
              </button>
            </div>
          </div>

          <div className="flex flex-wrap gap-2 mb-4">
            {impersonate === "1" && (
              <Link to="/dashboard/upload-jewellery">
                <button className="px-4 py-2 border-2 border-jw-golden rounded-lg text-jw-golden hover:bg-jw-golden hover:text-white transition-colors flex items-center gap-2">
                  <RiChatUploadLine className="w-5 h-5" />
                  <span className="hidden md:inline">Upload Jewellery</span>
                </button>
              </Link>
            )}
          </div>

          <div className="w-full bg-white h-auto mt-2">
            <HeaderTabs
              tabs={tabs}
              selectedTab={selectedTab.title}
              onSelectTab={handleSelectTab}
            />
            {GetJewelleryMutation.isLoading ? (
              <div className="">
                <Loader />
              </div>
            ) : (
              <div className="p-3">
                <div className="h-[480px] w-full overflow-x-auto  scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table class="min-w-full">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Image
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Tag Id
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Jewecle Id
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Purity
                          </th>

                          <th
                            scope="col"
                            className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Net Weight
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Gross Weight
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Wastage
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Size
                          </th>

                          {/* <th
                            scope="col"
                            className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            HUID
                          </th> */}
                          <th
                            scope="col"
                            className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody class="bg-white">{renderTableBody()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {!GetJewelleryMutation.isLoading && count !== 0 && (
              <div className="md:flex md:items-center md:justify-end p-3">
                {/* <div className="flex items-center space-x-4">
                  <label htmlFor="perPage" className="text-sm text-gray-600">
                    Per Page:
                  </label>
                  <div className="relative">
                    <select
                      id="perPage"
                      onChange={(e) => handlePePage(e)}
                      value={perPage}
                      className="block appearance-none w-24 bg-white border border-gray-300 text-gray-600 py-2 px-3 pr-8 rounded leading-tight  "
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div> */}

                {/* Pagination Controls */}
                <div className="flex items-center mt-3 md:mt-0">
                  <button
                    disabled={currentPage === 1}
                    onClick={handlePrev}
                    className={`px-3 py-2 border rounded-md text-gray-700 border-gray-300 ${
                      currentPage === 1
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-gray-200 focus:outline-none"
                    }`}
                    id="prev"
                  >
                    <span className="hidden md:inline">&laquo;&nbsp;</span>Prev
                  </button>

                  <div className="flex space-x-2">
                    {getDisplayedPages().map((page, index) => (
                      <button
                        key={index}
                        onClick={() => handlePageClick(page)}
                        className={`px-3 py-2 border rounded-md ${
                          page === currentPage
                            ? "font-semibold text-white bg-primary-pink"
                            : "text-gray-700 border-gray-300"
                        } hover:bg-gray-200 focus:outline-none`}
                        data-page={page}
                      >
                        {page}
                      </button>
                    ))}
                  </div>

                  <button
                    disabled={currentPage === totalPages}
                    onClick={handleNext}
                    className={`ml-2 px-3 py-2 border rounded-md text-gray-700 border-gray-300 ${
                      currentPage === totalPages
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-gray-200 focus:outline-none"
                    }`}
                    id="next"
                  >
                    Next&nbsp;<span className="hidden md:inline">&raquo;</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {addOrderModal && (
        <AddOrderModal
          isOpen={addOrderModal}
          closeModal={closeModal}
          jewelleryData={addOrderData}
        />
      )}
      {isViewProductModal && (
        <ViewProductModal
          isOpen={isViewProductModal}
          productDetails={productDetails}
          closeModal={() => setIsViewProductModal(false)}
          isLoading={GetProductByIdMutation.isLoading}
          fetchData={fetchJewellery}
          pageNo={page}
          perPage={perPage}
        />
      )}
      {renderDeleteModal()}
    </div>
  );
}
