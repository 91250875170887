import ZeroCharges from '../../Components/FrontView/ZeroCharges'
import WhyJwecle from '../../Components/FrontView/WhyJwecle'
import Learn from '../../Components/Learn/Learn'
import Support from '../../Components/Support/Support'
import Morquee from '../../Components/FrontView/Marquee'
import HomeBanner from '../../Components/FrontView/homeBanner'
import SellJewellerySteps from '../../Components/FrontView/sellJewellerySteps/sellJEwellerySteps'
function Home() {
  return (
    <>
      {/* <Loader/> */}
      <HomeBanner />
      <SellJewellerySteps />
      <ZeroCharges />
      <Morquee />
      <WhyJwecle />
      <Learn />
      <Support />
    </>
  )
}

export default Home
