import React, { useState, useEffect } from "react";
import FilterSidebar from "./FilterSidebar";
import { useGetSupplierCategoryJewellery } from "../../../hooks/profile";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../Components/Loader";
import { useSearchParams } from "react-router-dom";
import noResultsImage from "../../../assets/images/no-results-image.svg";

const JewelleryGrid = ({
  category,
  supplierId,
  filters,
  setFilters,
  onJewelleryClick,
  onCategoryBack,
  theme = {},
}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const perPage = 12;

  // Extract all category IDs when category changes
  const allCategoryIds = React.useMemo(() => {
    if (!category?.product_category) return [];

    const categories = Array.isArray(category.product_category)
      ? category.product_category
      : [category.product_category];

    return categories.map((cat) =>
      typeof cat === "string" ? cat : cat.product_category_id
    );
  }, [category]);

  // Initial setup when category changes
  useEffect(() => {
    if (!category?.category_id) return;

    // Get filters from URL or use defaults with all categories selected
    const urlFilters = {
      subCategory:
        searchParams.getAll("subCategory").length > 0
          ? searchParams.getAll("subCategory")
          : allCategoryIds,
      weight: searchParams.getAll("weight"),
      purity: searchParams.getAll("purity"),
    };

    // Set filters with all categories selected by default
    setFilters(urlFilters);

    // Update URL
    const newSearchParams = new URLSearchParams();
    newSearchParams.set("category", category.category_id);

    // Add all selected categories to URL
    urlFilters.subCategory.forEach((cat) =>
      newSearchParams.append("subCategory", cat)
    );
    urlFilters.weight.forEach((w) => newSearchParams.append("weight", w));
    urlFilters.purity.forEach((p) => newSearchParams.append("purity", p));

    setSearchParams(newSearchParams, { replace: true });
  }, [category?.category_id, allCategoryIds]);

  // Update URL when filters change
  useEffect(() => {
    if (!category?.category_id) return;

    const newSearchParams = new URLSearchParams();

    // Add category
    newSearchParams.set("category", category.category_id);

    // Add all filters to URL
    filters.subCategory.forEach((cat) =>
      newSearchParams.append("subCategory", cat)
    );
    filters.weight.forEach((w) => newSearchParams.append("weight", w));
    filters.purity.forEach((p) => newSearchParams.append("purity", p));

    // Update URL without replacing history
    setSearchParams(newSearchParams, { replace: false });
  }, [filters, category?.category_id]);

  // Memoize query parameters
  const queryConfig = React.useMemo(
    () => ({
      supplierId,
      categoryId: category?.category_id,
      perPage,
      filters,
    }),
    [supplierId, category?.category_id, filters]
  );

  // Use query hook with strict memoization
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useGetSupplierCategoryJewellery(
      queryConfig.supplierId,
      queryConfig.categoryId,
      queryConfig.perPage,
      queryConfig.filters
    );

  console.log("data", data);

  // Debug logging
  useEffect(() => {}, [queryConfig]);

  const allJewellery = React.useMemo(() => {
    if (!data?.pages) return [];
    return data.pages.flatMap((page) => page.responseData.data || []);
  }, [data?.pages]);

  const uniqueJewellery = React.useMemo(() => {
    return [...new Map(allJewellery.map((item) => [item._id, item])).values()];
  }, [allJewellery]);

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      console.log(
        "Product Categories:",
        uniqueJewellery.map((p) => p.product_category)
      );
    }
  }, [category, uniqueJewellery, filters]);

  const filterProduct = React.useCallback(
    (product) => {
      // Apply sub-category filter
      if (
        filters.subCategory.length > 0 &&
        !filters.subCategory.includes(product.product_category)
      ) {
        return false;
      }

      // Apply weight filter
      if (filters.weight.length > 0) {
        const weight = parseFloat(product.product_details?.gross_weight);
        if (!weight) return false;

        const passesWeight = filters.weight.some((range) => {
          const [min, max] = range.replace("g", "").split("-");
          if (max) {
            return weight >= parseFloat(min) && weight <= parseFloat(max);
          }
          return weight >= parseFloat(min);
        });
        if (!passesWeight) return false;
      }

      // Apply purity filter
      if (filters.purity.length > 0) {
        if (
          !filters.purity.includes(product.product_details?.purity?.toString())
        ) {
          return false;
        }
      }

      return true;
    },
    [filters]
  );

  const filteredProducts = React.useMemo(() => {
    if (!uniqueJewellery?.length) return [];
    return uniqueJewellery.filter(filterProduct);
  }, [uniqueJewellery, filterProduct]);

  // Handle infinite scroll next page
  const handleNextPage = React.useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  // Add debug logging
  useEffect(() => {}, [category, supplierId, filters]);

  const handleFilterChange = (type, value) => {
    setFilters((prev) => {
      if (type === "subCategory") {
        // Handle the select all case
        if (value.selectAll && Array.isArray(value.id)) {
          return {
            ...prev,
            subCategory: value.id,
          };
        }
        // Normal category toggle
        const currentValues = prev[type];
        const valueToCheck = value.id;

        return {
          ...prev,
          [type]: currentValues.includes(valueToCheck)
            ? currentValues.filter((v) => v !== valueToCheck)
            : [...currentValues, valueToCheck],
        };
      }

      // Handle other filter types (weight, purity)
      const currentValues = prev[type];
      return {
        ...prev,
        [type]: currentValues.includes(value)
          ? currentValues.filter((v) => v !== value)
          : [...currentValues, value],
      };
    });
  };

  // Add scroll reset on mount
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Also reset scroll when category changes
  useEffect(() => {
    if (category?.category_id) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [category?.category_id]);

  // Add useEffect to handle body scroll
  useEffect(() => {
    if (isFilterModalOpen) {
      // Prevent background scroll when modal is open
      document.body.style.overflow = "hidden";
    } else {
      // Restore scrolling when modal is closed
      document.body.style.overflow = "unset";
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isFilterModalOpen]);

  if (isLoading) {
    return <Loader />;
  }

  if (!category?.id && !category?.category_id) {
    return null;
  }

  return (
    <div className="flex flex-col md:flex-row gap-4 sm:gap-6">
      {/* Desktop Sidebar */}
      <div className="hidden md:block w-72">
        <div className="sticky top-6">
          <div className="backdrop-blur-xl bg-white/95 rounded-xl sm:rounded-2xl overflow-hidden border border-gray-200">
            <div className="p-4 sm:p-6 border-b border-gray-200">
              <h2
                className="text-lg sm:text-xl font-semibold"
                style={{ color: theme.secondary }}
              >
                Filters
              </h2>
            </div>
            <div className="p-4 sm:p-6">
              <FilterSidebar
                filters={filters}
                setFilters={setFilters}
                isModal={false}
                selectedCategory={category}
                onFilterChange={handleFilterChange}
                theme={theme}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 min-w-0">
        {/* Header Section */}
        <div
          className="backdrop-blur-xl bg-white/95 rounded-xl sm:rounded-2xl p-4 sm:p-6 mb-4 sm:mb-6 sticky top-0 z-50"
          style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
        >
          <div className="flex sm:flex-row items-start sm:items-center justify-between gap-3 sm:gap-4">
            <div className="flex items-center gap-3 sm:gap-4">
              <button
                onClick={onCategoryBack}
                className="flex items-center justify-center w-8 h-8 sm:w-10 sm:h-10 rounded-lg sm:rounded-xl transition-colors"
                style={{
                  backgroundColor: `${theme.secondary}20`,
                  color: theme.secondary,
                }}
              >
                <i className="fas fa-arrow-left text-sm sm:text-base"></i>
              </button>
              <div>
                <h1
                  className="text-lg sm:text-2xl font-bold"
                  style={{ color: theme.secondary }}
                >
                  {category?.name}
                </h1>
                <p className="text-xs sm:text-sm mt-0.5 sm:mt-1 text-gray-600">
                  {data.pages[0].responseData.totalCount} products available
                </p>
              </div>
            </div>

            {/* Mobile Filter Button */}
            <div className="flex items-center gap-3 sm:gap-4">
              <button
                onClick={() => setIsFilterModalOpen(true)}
                className="md:hidden flex items-center gap-2 px-3 py-1.5 sm:px-4 sm:py-2 rounded-lg sm:rounded-xl text-sm transition-colors"
                style={{
                  backgroundColor: theme.secondary,
                  color: "#ffffff",
                }}
              >
                <i className="fas fa-filter"></i>
                <span>Filters</span>
              </button>
            </div>
          </div>
        </div>

        {/* Products Grid */}
        <div className="backdrop-blur-xl bg-white/95 rounded-xl sm:rounded-2xl p-3 sm:p-6">
          {isLoading ? (
            <div className="flex justify-center py-12">
              <div
                className="animate-spin rounded-full h-12 w-12 border-b-2"
                style={{ borderColor: theme.secondary }}
              ></div>
            </div>
          ) : filteredProducts.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-12">
              <img
                src={noResultsImage}
                alt="No Results"
                className="w-32 h-32 mb-4"
              />
              <h2
                className="text-lg font-semibold"
                style={{ color: theme.secondary }}
              >
                No Results Found
              </h2>
              <p className="text-sm text-gray-600">
                Try adjusting your filters to find what you're looking for.
              </p>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={filteredProducts.length}
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={
                <div className="flex justify-center py-8">
                  <Loader />
                </div>
              }
              className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-6"
            >
              {filteredProducts.map((product) => (
                <div
                  key={product._id}
                  className="group bg-white rounded-2xl overflow-hidden border border-gray-100 hover:border-gray-200 transition-all duration-300 hover:-translate-y-1 hover:shadow-xl"
                >
                  <div className="relative w-full">
                    <div className="pt-[100%]">
                      <img
                        src={product.images?.[0]}
                        alt={product.product_name || product.name}
                        className="absolute inset-0 w-full h-full object-contain p-2"
                        onClick={() => onJewelleryClick(product)}
                      />
                    </div>
                    <div
                      className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                      style={{
                        background: `linear-gradient(to top, ${theme.secondary}cc, transparent)`,
                      }}
                    />
                  </div>

                  <div className="p-4">
                    <div className="flex items-center justify-between mb-2">
                      <p className="text-sm" style={{ color: theme.primary }}>
                        {product.category_name}
                      </p>
                      <div className="flex gap-1.5">
                        {product.product_details?.gross_weight && (
                          <span
                            className="px-2 py-0.5 rounded-md text-xs"
                            style={{
                              backgroundColor: `${theme.secondary}10`,
                              color: theme.secondary,
                            }}
                          >
                            {product.product_details.gross_weight}g
                          </span>
                        )}
                        {product.product_details?.purity && (
                          <span
                            className="px-2 py-0.5 rounded-md text-xs"
                            style={{
                              backgroundColor: `${theme.secondary}10`,
                              color: theme.secondary,
                            }}
                          >
                            {product.product_details.purity}K
                          </span>
                        )}
                      </div>
                    </div>

                    <h3
                      className="text-base font-semibold mb-3 line-clamp-1"
                      style={{ color: theme.secondary }}
                    >
                      {product.product_name || product.name}
                    </h3>

                    {product.isAr && (
                      <button
                        className="bg-gradient-to-r from-blue-500 to-purple-500 px-3 py-1.5 rounded-lg text-sm font-medium shadow-md transition-all duration-300 hover:scale-105 mb-2"
                        style={{ color: "#ffffff" }}
                      >
                        <i className="fas fa-cube mr-1"></i>
                        AR View
                      </button>
                    )}

                    <button
                      onClick={() => onJewelleryClick(product)}
                      className="w-full py-2 px-4 rounded-xl transition-all duration-300 flex items-center justify-center gap-2 text-white text-sm hover:opacity-90"
                      style={{
                        background: `linear-gradient(45deg, ${theme.primary}, ${theme.secondary})`,
                      }}
                    >
                      <span>View Details</span>
                      <i className="fas fa-arrow-right text-xs"></i>
                    </button>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>

      {/* Mobile Filter Modal */}
      {isFilterModalOpen && (
        <div className="fixed inset-0 z-50 md:hidden">
          {/* Backdrop */}
          <div
            className="fixed inset-0 bg-black/50 backdrop-blur-sm"
            onClick={() => setIsFilterModalOpen(false)}
          />

          {/* Slide-in Modal from right */}
          <div className="fixed right-0 top-0 h-full w-full sm:w-[320px] bg-white shadow-xl">
            {/* Header */}
            <div className="h-[60px] px-4 flex items-center justify-between border-b border-gray-200">
              <h3
                className="text-lg font-semibold"
                style={{ color: theme.secondary }}
              >
                Filters
              </h3>
              {/* <button
                onClick={() => setIsFilterModalOpen(false)}
                className="w-8 h-8 flex items-center justify-center rounded-lg transition-colors"
                style={{
                  backgroundColor: `${theme.secondary}10`,
                  color: theme.secondary,
                }}
              >
                <i className="fas fa-times"></i>
              </button> */}
              <button
                onClick={() => {
                  setIsFilterModalOpen(false);
                  // Add any additional logic needed to apply filters here
                }}
                className="w-1/2 py-2 px-4 rounded-xl text-white font-medium transition-opacity hover:opacity-90"
                style={{ backgroundColor: theme.secondary }}
              >
                Apply Filters
              </button>
            </div>

            {/* Filter Content */}
            <div className="h-[calc(100%-60px)] overflow-auto mt-2">
              <FilterSidebar
                filters={filters}
                setFilters={setFilters}
                isModal={true}
                onClose={() => setIsFilterModalOpen(false)}
                selectedCategory={category}
                onFilterChange={handleFilterChange}
                theme={theme}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JewelleryGrid;
