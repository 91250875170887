import React, { useState, useEffect, useContext } from "react";
import bell_ic from "../../assets/images/icons/bell_ic.svg";
import inr_ic from "../../assets/images/icons/inr_ic.svg";
import learning_ic from "../../assets/images/icons/learning_ic.svg";
import dp from "../../assets/images/icons/dp.png";
import bottom_mini_arrow from "../../assets/images/icons/bottom_mini_arrow.svg";
import { RxCross2 } from "react-icons/rx";
import { AiTwotoneNotification } from "react-icons/ai";
import { useGetUserProfile } from "../../hooks/profile";
import Dropdown from "../../Components/Dropdown";
import { useNavigate } from "react-router-dom";
import { contextData } from "../../context/ContextState";

function DashHeaderAction() {
  const { setBusinessName } = useContext(contextData);
  const profile = useGetUserProfile();
  const history = useNavigate();
  const [profileData, setProfileData] = useState();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const impersonate = localStorage.getItem("impersonate");

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("registration_step");
    localStorage.removeItem("impersonate");
    history("/");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await profile.mutateAsync();
        setProfileData(data.responseData);
        setBusinessName(data.responseData.business_name);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchData();
  }, []);
  const [isNotificationsOpen, setNotificationsOpen] = useState(false);
  const navigateToProfile = () => {
    history("/dashboard/profile");
    closeDropdown();
  };
  const stopImpersonate = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("registration_step");
    localStorage.removeItem("impersonate");
    history("/");
  };
  return (
    <>
      <div className="header-action mobile-header-action">
        {impersonate === "1" && (
          <button
            onClick={stopImpersonate}
            className="text-lg text-white flex font-semibold text-center  bg-primary-pink px-4 py-2 rounded-full   transition duration-300 ease-in-out transform hover:scale-105"
          >
            Stop impersonate
          </button>
        )}
        <div className="relative inline-block flex">
          <button
            className="bell-icon"
            onClick={() => setNotificationsOpen(!isNotificationsOpen)}
          >
            <img src={bell_ic} alt="bell icon" />
          </button>
          <div className="dropdown relative ml-2 md:hidden">
            <button className="bell-icon" onClick={toggleDropdown}>
              <img
                className="dp"
                src={profileData?.business_logo}
                alt=""
                width={15}
                height={15}
              />
            </button>
            {isDropdownOpen && (
              <Dropdown
                isOpen={isDropdownOpen}
                onClose={closeDropdown}
                onMyProfileClick={navigateToProfile}
                onLogoutClick={() => handleLogout()}
              />
            )}
          </div>
          {isNotificationsOpen && (
            <div className="notification-menu absolute top-full left-0 w-64 bg-white border border-gray-300 rounded shadow-sm mt-2 flex flex-col">
              <div className="flex justify-between items-center px-2 py-2 border-b">
                <span className="font-bold text-xl text-primary-pink">
                  Notifications
                </span>
                <button
                  className="text-xl text-red-500"
                  onClick={() => setNotificationsOpen(false)}
                >
                  {" "}
                  {/* Close button */}
                  <RxCross2 />
                </button>
              </div>
              <div className="notification-item flex items-center px-2 py-2 hover:bg-gray-100 shadow-sm">
                <AiTwotoneNotification />
                <p className="ml-2 text-gray-800">Notification 1...</p>
              </div>
              <div className="notification-item flex items-center px-2 py-2 hover:bg-gray-100 shadow-sm mt-1">
                <AiTwotoneNotification />
                <p className="ml-2 text-gray-800">Notification 2...</p>
              </div>
              <div className="notification-item flex items-center px-2 py-2 hover:bg-gray-100 shadow-sm mt-1">
                <AiTwotoneNotification />
                <p className="ml-2 text-gray-800">Notification 3...</p>
              </div>
            </div>
          )}
        </div>
        <div className="mobile-header-action">
          <button className="square-action" onClick={() => history("/pricing")}>
            <img src={inr_ic} alt="" width={15} height={15} />
            <span>Pricing</span>
          </button>
          <button className="square-action">
            <img src={learning_ic} alt="" width={15} height={15} />
            <span>Jewecle learning</span>
          </button>
          <div className="dropdown relative">
            <button className="square-action" onClick={toggleDropdown}>
              <img
                className="dp"
                src={profileData?.business_logo}
                alt=""
                width={15}
                height={15}
              />
              <span>{profileData?.business_name}</span>
            </button>

            {isDropdownOpen && (
              <Dropdown
                isOpen={isDropdownOpen}
                onClose={closeDropdown}
                onMyProfileClick={navigateToProfile}
                onLogoutClick={() => handleLogout()}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DashHeaderAction;
