// Import necessary components
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainLayout from "../Pages/MainLayout";
import Register from "../Pages/Register/Register";
import SignUpbusiness from "../Pages/Register/SignUpbusiness";
import Address from "../Pages/Register/Address";
import BankDetails from "../Pages/Register/BankDetails";
import Login from "../Pages/Register/Login";
import LoginPhone from "../Pages/Register/LoginPhone";
import HowItWorks from "../Pages/HowItWorks/HowItWorks";
import Home from "../Pages/Home/Home";
import OurSolution from "../Pages/OurSolution/OurSolution";
import DukanToBrand from "../Pages/DukanToBrand/DukanToBrand";
import Dashboard from "../Pages/Dashboard/Dashboard";
import ProductList from "../Pages/Dashboard/ProductList";
import InfoProduct from "../Pages/Dashboard/InfoProduct";
import UploadJwellery from "../Pages/Dashboard/UploadJwellery";
import AddCatalogue from "../Pages/Dashboard/Catalogue/AddCatalogue";
import Pricing from "../Pages/Pricing";

function Index() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />} />
        <Route path="/admin/supplier/register" element={<Register />} />
        <Route path="/admin/supplier/sign-up-business" element={<SignUpbusiness />} />
        <Route path="/admin/supplier/address" element={<Address />} />
        <Route path="/admin/supplier/bankdetails" element={<BankDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/home" element={<Home />} />
        <Route path="/our-solution" element={<OurSolution />} />
        <Route path="/dukan-to-brand" element={<DukanToBrand />} />
        <Route path="/login-with-phone" element={<LoginPhone />} />
        <Route path="/dashboard" element={<Dashboard />}>
          {/* <Route index={true} element={<InfoProduct />} /> */}
          <Route path="upload-jewellery" element={<UploadJwellery />} />
          <Route path="productlist" element={<ProductList />} />
          <Route path="addCatalogue" element={<AddCatalogue />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default Index;
