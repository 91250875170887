import React, { createContext, useState } from "react";
export const contextData = createContext(null);
function ContextState(props) {
  const [menuTogge, setmenuTogge] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const handleToggle = () => {
    setmenuTogge(menuTogge ? false : true);
  };
  return (
    <contextData.Provider
      value={{
        menuTogge,
        setmenuTogge,
        handleToggle,
        businessName,
        setBusinessName,
      }}
    >
      {props.children}
    </contextData.Provider>
  );
}

export default ContextState;
