import React, { useState, useEffect } from 'react';
import FullscreenImageViewer from './FullscreenImageViewer';

const JewelleryModal = ({ isOpen, onClose, jewellery, theme }) => {
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      // Set the first image as selected by default
      setSelectedImage(jewellery?.images?.[0]);
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, jewellery]);

  if (!isOpen || !jewellery) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black/70 flex items-center justify-center z-40 p-4">
        <div 
          className="bg-white rounded-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto relative"
          onClick={e => e.stopPropagation()}
        >
          <button 
            onClick={onClose}
            className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full transition-colors z-10"
          >
            <i className="fas fa-times text-gray-500 text-xl"></i>
          </button>
          
          <div className="grid md:grid-cols-2 gap-8 p-6">
            <div>
              <img 
                src={selectedImage || jewellery.images?.[0]} 
                alt={jewellery.product_name || jewellery.name}
                className="w-full h-[400px] object-contain bg-gray-50 rounded-lg cursor-zoom-in hover:opacity-90 transition-opacity"
                onClick={() => setIsFullscreenOpen(true)}
              />
              {jewellery.images?.length > 1 && (
                <div className="grid grid-cols-4 gap-2 mt-4">
                  {jewellery.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`${jewellery.product_name || jewellery.name} - ${index + 1}`}
                      className={`w-full h-20 object-contain bg-gray-50 rounded-md cursor-pointer hover:opacity-90 transition-opacity
                        ${selectedImage === image ? 'ring-2 ring-gray-800' : ''}`}
                      onClick={() => setSelectedImage(image)}
                    />
                  ))}
                </div>
              )}
            </div>
            
            <div className="space-y-6">
              <div>
                <p className="text-sm text-gray-500 mb-1">
                  {jewellery.category_name}
                </p>
                <h2 className="text-2xl font-bold text-gray-800">
                  {jewellery.product_name || jewellery.name}
                </h2>
              </div>
              
              <div className="grid grid-cols-2 gap-4">
                {jewellery.product_details?.gross_weight && (
                  <div>
                    <p className="text-sm text-gray-500">Weight</p>
                    <p className="text-gray-800">{jewellery.product_details.gross_weight}g</p>
                  </div>
                )}
                {jewellery.product_details?.purity && (
                  <div>
                    <p className="text-sm text-gray-500">Purity</p>
                    <p className="text-gray-800">{jewellery.product_details.purity}K</p>
                  </div>
                )}
                {jewellery.product_details?.color && (
                  <div>
                    <p className="text-sm text-gray-500">Color</p>
                    <p className="text-gray-800">{jewellery.product_details.color}</p>
                  </div>
                )}
                {jewellery.product_details?.size && (
                  <div>
                    <p className="text-sm text-gray-500">Size</p>
                    <p className="text-gray-800">{jewellery.product_details.size}</p>
                  </div>
                )}
              </div>

              {/* Dimensions Section */}
              {(jewellery.product_details?.length || 
                jewellery.product_details?.width || 
                jewellery.product_details?.breadth) && (
                <div>
                  <p className="text-sm text-gray-500 mb-1">Dimensions</p>
                  <p className="text-gray-800">
                    {[
                      jewellery.product_details.length,
                      jewellery.product_details.width,
                      jewellery.product_details.breadth
                    ].filter(Boolean).join(' x ')}
                    {' '}{jewellery.product_details.measurement_in}
                  </p>
                </div>
              )}
              
              {jewellery.description && (
                <div>
                  <p className="text-sm text-gray-500 mb-1">Description</p>
                  <p className="text-gray-600 leading-relaxed">
                    {jewellery.description}
                  </p>
                </div>
              )}
              
              {jewellery.isAr && (
                <button 
                  className="w-full text-white py-3 rounded-md transition-colors"
                  style={{ 
                    backgroundColor: theme.secondary,
                    '&:hover': { backgroundColor: theme.secondaryHover }
                  }}
                >
                  <i className="fas fa-cube mr-2"></i>
                  Try with AR
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <FullscreenImageViewer
        isOpen={isFullscreenOpen}
        onClose={() => setIsFullscreenOpen(false)}
        imageUrl={selectedImage || jewellery.images?.[0]}
        images={jewellery.images || []}
        onImageChange={setSelectedImage}
      />
    </>
  );
};

export default JewelleryModal; 