import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import wave_banner from "../../assets/images/wave_banner.svg";
import j1 from "../../assets/1.jpeg";
import j4 from "../../assets/2.jpeg";
import ring from "../../assets/6.jpeg";
import chain from "../../assets/chain.jpeg";
import necklace from "../../assets/necklace.jpej";
import infinity from "../../assets/buti.jpeg";
import pend from "../../assets/pend.jpeg";
import set from "../../assets/set.jpeg";

const HomeBanner = () => {
  return (
    <>
      <section className="banner main-banner pb-0 pt-0">
        <Container>
          <Row>
            <Col md={6}>
              <div className="banner-content">
                <div className="banner-title">
                  <h1 className="text-3xl lg:text-5xl">
                    Jo stock <span className="pink-color-text">dikhta</span>{" "}
                    hain, wahi stock{" "}
                    <span className="pink-color-text">bikta</span> hain
                  </h1>
                </div>
                <div className="banner-desc">
                  <h5 className="text-xl lg:text-2xl">
                    Unlock Endless Gold Collections: Connect with Top <span className="pink-color-text">Retailers</span> Today!
                    {/* now showcase your unlimited{" "}
                    <span className="pink-color-text">gold jewelleries</span> to
                    each jewecle retailer and connect with them. */}
                  </h5>
                </div>
                {/* {
                                !token && <MobileVerify />
                            } */}
              </div>
            </Col>
            <Col md={6}></Col>
          </Row>
        </Container>
        <div className="group-img-container overflow-x-auto flex">
          <ul className="img-column list-none p-0 m-0 flex flex-nowrap space-x-4">
            <li className="flex-shrink-0">
              <div className="img-wrap w-48 h-48 flex items-center justify-center transition-transform transform hover:scale-105 duration-500 ease-in-out">
                <img src={ring} alt="" className="w-full h-full object-cover" />
              </div>
            </li>
            <li className="flex-shrink-0">
              <div className="img-wrap w-48 h-48 flex items-center justify-center transition-transform transform hover:scale-105 duration-500 ease-in-out">
                <img src={j1} alt="" className="w-full h-full object-cover" />
              </div>
              <div className="img-wrap w-48 h-48 mt-2 flex items-center justify-center transition-transform transform hover:scale-105 duration-500 ease-in-out">
                <img src={j4} alt="" className="w-full h-full object-cover" />
              </div>
            </li>
            <li className="flex-shrink-0">
              <div className="img-wrap w-48 h-48 flex items-center justify-center transition-transform transform hover:scale-105 duration-500 ease-in-out">
                <img src={set} alt="" className="w-full h-full object-cover" />
              </div>
              <div className="img-wrap w-48 h-48 mt-2 flex items-center justify-center transition-transform transform hover:scale-105 duration-500 ease-in-out">
                <img
                  src={necklace}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="img-wrap w-48 h-32 mt-2 flex items-center justify-center transition-transform transform hover:scale-105 duration-500 ease-in-out">
                {/* <img
                  src={infinity}
                  alt=""
                  className="w-full h-full object-cover"
                /> */}
              </div>
            </li>
            <li className="flex-shrink-0">
              <div className="img-wrap w-48 h-48 flex items-center justify-center transition-transform transform hover:scale-105 duration-500 ease-in-out">
                <img src={pend} className="w-full h-full object-cover" alt="" />
              </div>
              <div className="img-wrap w-48 h-48 mt-2 flex items-center justify-center transition-transform transform hover:scale-105 duration-500 ease-in-out">
                <img
                  src={chain}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>

        <div className="wave">
          <img src={wave_banner} alt="" />
        </div>
      </section>
    </>
  );
};

export default HomeBanner;
