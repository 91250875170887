import React, { useCallback } from 'react';

const CategorySection = ({ onCategorySelect, products, theme }) => {
  // Transform products while maintaining order
  const categoryArray = React.useMemo(() => {
    if (!products) return [];
    
    return products.map(product => ({
      id: product._id || product.product_id,
      category_id: product.product_id || product._id,
      name: product.name,
      image: product.sampleImage,
      description: `${product.name} collection`,
      itemCount: `${product.jewellery_count} Items`,
      product_category: product.product_category,
      products: [product]
    }));
  }, [products]);

  // Memoize the click handler
  const handleCategoryClick = useCallback((category) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add a small delay to ensure smooth scroll completes
    requestAnimationFrame(() => {
      onCategorySelect(category);
    });
  }, [onCategorySelect]);

  return (
    <div className="py-4 sm:py-8">
      <div className="text-center mb-6 sm:mb-12">
        <h2 className="text-2xl sm:text-3xl font-bold text-white mb-2 sm:mb-4">Our Collections</h2>
        <p className="text-white/80 max-w-2xl mx-auto px-4">
          Explore our carefully curated categories of fine jewelry
        </p>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-8 px-2 sm:px-0">
        {categoryArray.map((category) => (
          <div 
            key={category.id}
            onClick={() => handleCategoryClick(category)}
            className="group relative overflow-hidden rounded-xl sm:rounded-2xl cursor-pointer bg-white/95 backdrop-blur-xl"
            style={{
              '--theme-primary': theme.primary,
              '--theme-secondary': theme.secondary,
            }}
          >
            <div className="aspect-[3/4] sm:aspect-[4/5] overflow-hidden">
              <img 
                src={category.image} 
                alt={category.name}
                className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent opacity-80 group-hover:opacity-90 transition-opacity duration-300" />
            </div>

            <div className="absolute inset-0 flex flex-col justify-end p-3 sm:p-6 text-white">
              <div className="transform translate-y-2 sm:translate-y-4 group-hover:translate-y-0 transition-transform duration-300">
                <h3 className="text-lg sm:text-2xl font-bold mb-1 sm:mb-2">{category.name}</h3>
                <p className="text-xs sm:text-sm text-white/80 mb-1 sm:mb-2 hidden sm:block opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {category.description}
                </p>
                <div className="flex items-center justify-between">
                  <span className="text-xs sm:text-sm font-medium">{category.itemCount}</span>
                  <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <i className="fas fa-arrow-right text-sm sm:text-base"></i>
                  </span>
                </div>
              </div>
            </div>

            <div className="absolute inset-0 border border-white/0 group-hover:border-white/50 rounded-xl sm:rounded-2xl transition-colors duration-300" 
              style={{ borderColor: `${theme.primary}50` }} 
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategorySection;